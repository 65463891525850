import React from 'react';
import './BrowserNotSupported.css';

const BrowserNotSupported = () => {
    return (
        <div className="flex items-center justify-center h-screen">

            <div style={{width: 790}} className="bg-white shadow-md border border-gray-500 p-10">

                <h3 className="text 2xl font-semibold mb-10">Helaas, je browser wordt niet ondersteund</h3>
                <p>Het spijt ons, maar je surft met een verouderde browser of met een browser die niet wordt ondersteund. Enkel de browsers Chrome, Firefox, Edge en Safari worden ondersteund.</p>
                <p><strong> Gebruik steeds de meest recente versie. </strong></p>

                <div className="browser_options">
                    <a className="browser_button button--chrome" href="https://www.google.com/chrome/browser/" target="_blank" rel="noopener noreferrer">Chrome</a>
                    <a className="browser_button button--firefox" href="http://www.getfirefox.com" target="_blank" rel="noopener noreferrer">Firefox</a>
                    <a className="browser_button button--edge" href="https://www.microsoft.com/nl-be/windows/microsoft-edge" target="_blank" rel="noopener noreferrer">Microsoft Edge</a>
                    <a className="browser_button button--safari" href="https://www.apple.com/safari/" target="_blank" rel="noopener noreferrer">Safari</a>
                </div>
            </div>
        </div>
    )
};
export default BrowserNotSupported;
