import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslation from './translations/en/en.json';
import nlTranslation from './translations/nl/nl.json';
import frTranslation from './translations/fr/fr.json';

const resources = {
    en: {
        translation: enTranslation,
    },
    nl: {
        translation: nlTranslation,
    },
    fr: {
        translation: frTranslation,
    }
};

const languageDetector = new LanguageDetector(null, {
    // order and from where user language should be detected
    order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

    // keys or params to lookup language from
    lookupCookie: 'lang',


    // // cache user language on
    caches: ['localStorage', 'cookie'],
    // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
    //
    // // optional expire and domain for set cookie
    // cookieMinutes: 10,
    // cookieDomain: 'myDomain',
    //
    // // optional htmlTag with lang attribute, the default is:
    // htmlTag: document.documentElement,
    //
    // // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
    // cookieOptions: {path: '/', sameSite: 'strict'}
});


i18n
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        resources,
        cookie: 'lang',
        fallbackLng: 'en',
        debug: false,
        keySeparator: false,
        returnEmptyString: false,
        // interpolation: {
        //     escapeValue: false, // not needed for react as it escapes by default
        // }
    });


export default i18n;

