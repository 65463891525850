import React, {useContext, useState, useEffect} from 'react';
import analyticstracker from "analyticstracker";

import DropContainer from "../components/DropContainer";
import StatusGeneral from "../components/StatusGeneral";
import {useTranslation} from "react-i18next";
import DataContext from "../../../data/context";
import {LoadingIndicator} from "../../indicators";
import { TAGMANAGER_PAGE_ARGS } from "../../../tagManager";

const getIconForExtension = (extension) => {
    const cleaned_ext = extension.toLowerCase().replace(".", "");
    switch (cleaned_ext) {
        case 'pdf':
            return "fal fa-file-pdf";
        case 'jpg':
        case 'jpeg':
        case 'png':
            return "fal fa-file-image";
        default:
            return 'fal fa-file'
    }
};

const ProposedDocumentUpload = ({onConfirm, confirmLoading}) => {
    const {takeOver} = useContext(DataContext);
    const [loading, setLoading] = useState(false);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        analyticstracker().trackImpression("page-impression");
      }, []);

    const tagInfo = TAGMANAGER_PAGE_ARGS({
        pageName: "car-quotation/valuation-2",
        section: "valuation-2-proposal-accepted",
        language: i18n.language,
    });

    return (
        <div
            data-tracking-event="page-impression"
            data-tracking-info={tagInfo}>
            <StatusGeneral title={(takeOver.documents.length === 0) ? t("Congratulations! You're almost there... ") : t("Signed convention document(s)")} statusTableVisible={false}/>

            {loading && <LoadingIndicator/>}

            {!loading && takeOver.documents.length === 0 &&
            <div className="flex bg-color_five rounded-lg p-6 mt-4 cursor-pointer" onClick={() => {
                window.open(takeOver.documentUrl, '_blank')
            }}>
                <div className="flex items-center justify-center" style={{width: 75}}>
                    <div className="flex bg-white rounded-full items-center justify-center" style={{width: 60, height: 60}}>
                        <span className="text-16 text-color_three font-bold">1</span>
                    </div>
                </div>
                <div className="flex flex-1 flex-col justify-center items-center">
                    <div>
                        <i className="fal fa-file-download  text-34 mb-4 text-color_one"/>
                    </div>

                    <div>
                        <span className="font-bold text-color_one underline">{t("Download")}</span> <span className="text-color_three">{t("the takeover convention")}</span>
                    </div>
                </div>
            </div>}

            {!loading && takeOver.documents.length === 0 &&
            <div className="flex bg-color_five rounded-lg p-6 mt-4">
                <div className="flex items-center justify-center" style={{width: 75}}>
                    <div className="flex bg-white rounded-full items-center justify-center" style={{width: 60, height: 60}}>
                        <span className="text-16 text-color_three font-bold">2</span>
                    </div>
                </div>
                <div className="flex flex-1 flex-col justify-center items-center">
                    <div>
                        <i className="fal fa-signature text-34 mb-4 text-color_one"/>
                        <i className="fal fa-pen-fancy text-34 mb-4 text-color_one"/>
                    </div>

                    <div>
                        <span className="font-bold text-color_one">{t("Print and sign")}</span> <span className="text-color_three">{t("the takeover convention")}</span>
                    </div>
                </div>
            </div>}

            {!loading &&
            <React.Fragment>

                <DropContainer onDrop={() => {
                    if (takeOver.documents.length === 0) {
                        setLoading(true)
                    }
                }} onUploadCompleted={() => setLoading(false)}>
                    {takeOver.documents.length === 0 &&
                    <div className="flex flex-1 bg-color_five rounded-lg p-6 mt-4">
                        <div className="flex items-center justify-center" style={{width: 75}}>
                            <div className="flex bg-white rounded-full items-center justify-center" style={{width: 60, height: 60}}>
                                <span className="text-16 text-color_three font-bold">3</span>
                            </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-center items-center">
                            <div>
                                <i className="fal fa-file-upload text-34 mb-4 text-color_one"/>
                            </div>

                            <div>
                                <span className="font-bold text-color_one">{t("Upload")}</span> <span className="text-color_three">{t("the signed document here")}</span>
                            </div>
                        </div>
                    </div>}

                    {takeOver.documents.length > 0 &&
                    <div className="flex flex-1 bg-color_five rounded-lg p-6 mt-4 mb-4">
                        {takeOver.documents.length === 0 &&
                        <div className="flex items-center justify-center" style={{width: 75}}>
                            <div className="flex bg-white rounded-full items-center justify-center" style={{width: 60, height: 60}}>
                                <span className="text-16 text-color_three font-bold">3</span>
                            </div>
                        </div>}

                        <div className="flex flex-1 flex-col justify-center items-center">
                            <div className="flex">
                                {takeOver.documents.map((doc) => {
                                    return (
                                        <div className="text-color_one mb-4 mr-4" style={{maxWidth: 80}} onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(doc.url, "_blank")
                                        }}>

                                            {doc.url !== undefined &&
                                            <div className="flex items-center  justify-center">
                                                <i className={`${getIconForExtension(doc.extension)} text-24 mb-2`}/>
                                            </div>}

                                            {doc.url === undefined &&
                                            <div className="flex items-center justify-center" style={{width: 30, height: 30}}>
                                                <LoadingIndicator small={true}/>
                                            </div>}

                                            <div className="text-center truncate"> {doc.originalFileName}</div>
                                        </div>)
                                })}
                            </div>

                            <div>
                                <span className="text-color_three text-10 text-10 ">{t("Add multiple documents by clicking or dragging.")}</span>
                            </div>

                        </div>
                    </div>}
                </DropContainer>


            </React.Fragment>}


            {!loading && takeOver.documents.length > 0 &&
            <div className="md:flex md:justify-center w-full md:items-center mb-8">
                <div className="flex rounded-full items-center justify-center text-center font-bold cursor-pointer py-3 px-5 text-14 tracking-widest bg-color_one text-white uppercase"
                     onClick={onConfirm} style={{minWidth: 205, minHeight: 45}}>

                    {confirmLoading &&
                    <div className="flex items-center">
                        <LoadingIndicator extraSmall color="white"/>
                    </div>}
                    {!confirmLoading &&
                    <div>{t("Confirm the inspection")}
                    </div>}

                </div>
            </div>}
        </div>
    );
};

export default ProposedDocumentUpload;
