import React from 'react';
import {useTranslation} from "react-i18next";

const UploadPicture = ({url, onDeletePicture}) => {
    const { t } = useTranslation();

    return (
        <div className="relative flex flex-1 w-full items-center justify-center overflow-hidden rounded-lg mb-2">
            <div className="absolute right-0 top-0 rounded-tr-lg rounded-bl-lg p-2 bg-color_three flex items-center justify-center text-white hover:text-gray-200 cursor-pointer" onClick={onDeletePicture}>
                <i className="fal fa-trash-alt text-24" />
            </div>
            {!url && <div>{t('Loading...')}</div>}
            <img src={url} style={{width: "100%"}} className="rounded-lg" alt="Vehicle"/>
        </div>
    );
};

export default UploadPicture;
