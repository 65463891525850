import React, {useContext, useState} from 'react';
import {hasValue} from "../../validators";
import TextField from "../input/TextField";
import {useTranslation} from "react-i18next";
import {useHistory, useParams, Redirect} from "react-router-dom";
import DataContext from "../../data/context";
import {useMutation} from "graphql-hooks";
import {fetchUserData} from "../../data/session";


export const ACCOUNT_RESET_PASSWORD_MUTATION = `
    mutation AccountResetPassword($data: AccountResetPasswordInputType!) {
        accountResetPassword(data: $data) {
           ok
           token
           user {
             firstName,
             lastName
           }
        }
    }`;

const ResetPassword = () => {
        const {resetCode} = useParams();
        const {dealerKey, dealerConfig, setAuthenticated} = useContext(DataContext);
        const [password, setPassword] = useState("");
        const [passwordConfirm, setPasswordConfirm] = useState("");
        const [accountResetPasswordMutation] = useMutation(ACCOUNT_RESET_PASSWORD_MUTATION);
        const [errors, setErrors] = useState({});
        const {t} = useTranslation();
        let history = useHistory();

        const handleSubmit = (e) => {
            e.preventDefault();

            if (validate() && resetCode.includes('+')) {
                const parts = resetCode.split('+');
                const key = parts[0];
                const email = parts[1];

                accountResetPasswordMutation({
                    variables: {
                        data: {
                            dealer: dealerKey,
                            email: email,
                            key: key,
                            password: password,
                        }
                    }
                }).then((result) => {
                    if (result.data && result.data.accountResetPassword) {
                        const {accountResetPassword} = result.data;
                        if (accountResetPassword.ok) {

                            fetchUserData(dealerKey).then((user) => {
                                setAuthenticated(user);
                            });

                            history.push(`/${dealerKey}/account`)
                        }
                    } else if (result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
                        const error = result.error.graphQLErrors[0].message;
                        let tmpErrors = {...errors};
                        tmpErrors['resetpw'] = error;
                        setErrors(tmpErrors)
                    } else {
                        let tmpErrors = {...errors};
                        tmpErrors['resetpw'] = 'Something went wrong';
                        setErrors(tmpErrors);
                    }
                });
            }
        };

        const validate = () => {
            let errors = {};

            if (!hasValue(password)) {
                errors['email'] = "Ongeldig wachtwoord";
            }

            if (!hasValue(passwordConfirm)) {
                errors['email'] = "Ongeldig wachtwoord";
            }

            if (password !== passwordConfirm) {
                errors['resetpassword'] = 'Wachtwoorden zijn niet gelijk'
            }

            setErrors(errors);

            return Object.keys(errors).length <= 0;
        };

        if (!dealerConfig.account) {
            return <Redirect to={`/${dealerKey}`}/>
        }

        return (
            <div className="" style={{minWidth: 300}}>
                <div className="mb-4">

                    <TextField label={t('Password')}
                               type="password"
                               value={password}
                               onChange={(value) => setPassword(value)}
                               error={errors['password']}
                    />

                    <TextField label={t('Confirm password')}
                               type="password"
                               value={passwordConfirm}
                               onChange={(value) => setPasswordConfirm(value)}
                               error={errors['passwordConfirm']}
                    />

                </div>


                {Object.keys(errors).length > 0 && errors['resetpw'] !== "" &&
                <div className="bg-red-200 text-red-800 p-4 w-full mb-4">
                    {errors['resetpw']}
                </div>}


                <div className="mb-4">
                    <div className="flex rounded-full uppercase bg-white border-2 border-black text-center items-center justify-center font-bold text-12 cursor-pointer tracking-widest md:w-1/2"
                         style={{minHeight: 40}}
                         onClick={handleSubmit}>
                        {t('Versturen')}
                    </div>
                </div>
            </div>

        );
    }
;

export default ResetPassword;
