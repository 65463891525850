import React from 'react';
import Select from "react-select";

const SelectField = ({label, value, options, onChange, required, loading, placeholder, error}) => {
        return (
        <div>
            {label &&
            <label className={`block uppercase tracking-widest text-14 font-bold mb-1 text-color_one ml-5`}>
                {label} {required ? "(*)" : ""}
            </label>}

            <Select
                value={value}
                options={options}
                isLoading={loading}
                onChange={onChange}
                placeholder={placeholder}
                className="cursor-pointer select-"
                classNamePrefix={`${error ? 'SelectFieldError' : 'SelectField'}`}
            />
            {error &&
            <div className="text-color_error text-12 ml-5 mt-2"> * {error} </div>}
        </div>
    );
};

SelectField.defaultProps = {};

export default SelectField;
