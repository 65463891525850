import React from 'react';

const ColorPicker = ({value, colors, onSelect, error}) => {
    return (
        <React.Fragment>
            <div className="flex flex-row relative flex-wrap ">
                {colors.map((color, i) => {
                    const isSelected = color.key === value;

                    let extColorBg = isSelected ? "border-color_one" : "border-gray-200";

                    return (
                        <div key={i} className={`rounded-lg p-1 border ${extColorBg} mr-2 mb-2`} style={{padding: 6, width: 34, height: 34}}
                             onClick={() => onSelect(color)}>
                            <div className="flex items-center justify-center w-full h-full rounded" style={{backgroundColor: color.colorCode}}>
                                {isSelected && <i className="fal fa-check text-12"/>}
                            </div>
                        </div>
                    )
                })}
            </div>
            {error &&
            <div className="text-color_error text-12 mt-2"> * {error} </div>}
        </React.Fragment>

    );
};

export default ColorPicker;
