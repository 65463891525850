import React, { useContext, useEffect, useState } from "react";
import WizardButtons from "../WizardButtons";
import { useMutation, useQuery } from "graphql-hooks";
import InspectionPicture from "../InspectionPicture";
import { find, forEach, includes, keys, map, orderBy, filter } from "lodash";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import { base64ToFile } from "../../../utils";
import { v4 } from "uuid";
import CameraModal from "../../camera/CameraModal";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const pictureTypesQuery = `query PictureTypes {
  pictureTypes{
     id    
     key
     description    
     priority
     mandatory
  }
}`;

const picturesMutation = `mutation SetPictures($key: String!, $data: [PictureInputType]!) { 
        setPictures(key: $key data: $data) {
            ok,           
        }
    }`;

export const FileUploadMutation = `mutation FileUpload($file: Upload!) { 
        fileUpload(file: $file) { 
            ok                
            url
        }
    }`;

export const getOverlay = (pictureType) => {
  try {
    return require(`../../../assets/overlays/${pictureType.key}.svg`);
  } catch (ex) {
    return undefined;
  }
};

const Pictures = () => {
  const {
    inspection,
    addPicture,
    setValidationErrorsModalVisible,
  } = useContext(DataContext);
  const { data: pictureTypesData, refetch: reFetchPictureTypes } = useQuery(
    pictureTypesQuery
  );
  const [setPicturesMutation] = useMutation(picturesMutation);
  const [errors, setErrors] = useState({});
  const [cameraVisible, setCameraVisible] = useState(false);
  const [fileUploadMutation, { loading }] = useMutation(FileUploadMutation);
  const [selectedPictureType, setSelectedPictureType] = useState(null);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate(false);
    }
  }, [inspection]);
  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);
  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "picture",
    toolStepNumber: "1",
    inspectionPartTwo: true,
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({
    carConfigCode: inspection.key,
  });

  useEffect(() => {
    reFetchPictureTypes();
  }, [i18n.language]);

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      const ptKey = keys(errors)[0];
      const element = document.getElementById(`pt_${ptKey}`);
      element.scrollIntoView();
      window.scrollBy(0, -120);
    }
  }, [errors]);

  const validate = (showModal = true) => {
    let errors = {};

    const inspectionPicRefTypes = map(inspection.pictures, (p) => p.type);
    forEach(pictureTypesData.pictureTypes, (pt) => {
      if (pt.mandatory && !includes(inspectionPicRefTypes, pt.key)) {
        errors[pt.key] = `${t("Picture")} ${pt.description} ${t(
          "is required"
        )}`;
      }
    });

    setErrors(errors);

    if (showModal && keys(errors).length > 0) {
      // send all errors as events
      Object.values(errors).map((message) => {
        let aTrack = analyticstracker();
        let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
          event: "error",
          toolStep: "picture",
          toolStepNumber: "1",
          errorType: "user-error",
          errorMessage: message,
          inspectionPartTwo: true,
        });
        let event = {
          event: "tool-error",
          info: JSON.parse(errorTrackingInfo),
          commerce: JSON.parse(tagArgsCommerce)
        };
        aTrack.trackEvent(event);
      });
      setValidationErrorsModalVisible(errors);
    }

    return keys(errors).length <= 0;
  };

  const validator = () => {
    const valid = validate();
    if (valid) {
      return setPicturesMutation({
        variables: {
          key: inspection.key,
          data: map(inspection.pictures, (picture) => ({
            key: picture.key,
            url: picture.url,
            type: picture.type,
          })),
        },
      }).then((result) => {
        if (
          result &&
          result.data &&
          result.data.setPictures &&
          result.data.setPictures.ok
        ) {
          return true;
        }
        return false;
      });
    }
  };

  const handleFileUpload = (file, key) => {
    fileUploadMutation({ variables: { file: file } })
      .then((res) => {
        const { ok, url } = res.data.fileUpload;
        if (ok) {
          addPicture(v4(), url, key);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const closeFullScreen = () => {
    try {
      if (document.fullscreenElement) {
        document.exitFullscreen().catch((err) => {
          console.log(
            `Error attempting exit full-screen mode: ${err.message} (${err.name})`
          );
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const renderInspectionPicture = (picture, pt, counter = undefined) => {
    if (pt === undefined) return null;

    return (
      <InspectionPicture
        key={`type_${pt.key} ${counter ? counter : ""}`}
        pictureType={pt}
        picture={picture}
        pictureTypes={pictureTypes}
        error={errors[pt.key] && errors[pt.key] !== ""}
        handleFileUpload={(file, key) => handleFileUpload(file, key)}
        setCameraVisible={() => {
          if (!cameraVisible) {
            setSelectedPictureType(pt);
            setCameraVisible(true);
          }
        }}
        loading={
          selectedPictureType && selectedPictureType.key === pt.key && loading
        }
      />
    );
  };

  const getNextPictureType = (types, pictures, currentType) => {
    const currentIndex =
      currentType !== undefined
        ? types.findIndex((pt) => pt.key === currentType.key)
        : null;

    if (currentIndex === null) return null;

    if (currentIndex < 0) {
      return null;
    } else {
      const nextIndex = getNextIndex(types, pictures, currentIndex);
      return nextIndex < pictureTypes.length ? types[nextIndex] : undefined;
    }
  };

  const getNextIndex = (pictureTypes, pictures, index) => {
    const nextIndex = index + 1;
    const nextPictureType = pictureTypes[nextIndex]
      ? pictureTypes[nextIndex]
      : undefined;

    if (nextPictureType === undefined) {
      return nextPictureType;
    }
    const picture = find(pictures, (pic) => pic.type === nextPictureType.key);

    if (picture) {
      return getNextIndex(pictureTypes, pictures, nextIndex);
    } else {
      return nextIndex;
    }
  };

  const allPictureTypes =
    pictureTypesData && pictureTypesData.pictureTypes
      ? pictureTypesData.pictureTypes
      : [];
  const pictureTypesWithNoOther = filter(
    allPictureTypes,
    (pt) => pt.key !== "other"
  );
  const pictureTypes = pictureTypesWithNoOther
    ? orderBy(pictureTypesWithNoOther, ["priority", "description"], ["asc"])
    : [];
  const otherPictures = inspection.pictures
    ? filter(inspection.pictures, (pic) => pic.type === "other")
    : [];
  const otherPictureType =
    pictureTypesData && pictureTypesData.pictureTypes
      ? find(pictureTypesData.pictureTypes, (pt) => pt.key === "other")
      : undefined;

  return (
    <div
      className="ml-8 mr-8 mt-0 lg:mt-10"
      data-tracking-event="tool-start"
      data-tracking-info={tagArgsStart}
      data-tracking-commerce={tagArgsCommerce}
    >
      {/*<p className="text-color_three text-14 mb-8 leading-5">*/}
      {/*    {t('We always recommend to take pictures in natural daylight, on a spacious parking area. Empty the car so that the seats and the inside of trunk are visible. For each view you’ll find an example picture on the right. Note that we’ll be able to offer you the best takeover price if you provide us with clear and high quality pictures.')}*/}
      {/*</p>*/}

      <div className="mb-10 flex flex-col md:flex-row md:flex-wrap">
        {map(pictureTypes, (pt) => {
          const picture = find(
            inspection.pictures,
            (pic) => pic.type === pt.key
          );
          return renderInspectionPicture(picture, pt);
        })}

        {map(otherPictures, (picture, i) => {
          return renderInspectionPicture(picture, otherPictureType, i);
        })}

        {renderInspectionPicture(undefined, otherPictureType)}

        <CameraModal
          isOpen={cameraVisible}
          onOpen={() => setCameraVisible(true)}
          overlay={
            selectedPictureType
              ? getOverlay(
                  pictureTypes.find((pt) => pt.key === selectedPictureType.key)
                )
              : undefined
          }
          pictureTypes={pictureTypes}
          pictureType={
            selectedPictureType
              ? pictureTypes.find((pt) => pt.key === selectedPictureType.key)
              : undefined
          }
          hasNext={() =>
            getNextPictureType(
              pictureTypes,
              inspection.pictures,
              selectedPictureType
            )
          }
          onNext={() => {
            const nextType = getNextPictureType(
              pictureTypes,
              inspection.pictures,
              selectedPictureType
            );
            setSelectedPictureType(nextType);
          }}
          onClose={() => {
            setCameraVisible(false);
            setSelectedPictureType(undefined);
            closeFullScreen();
          }}
          onChooseFile={(file, key) => {
            handleFileUpload(file, key);
            closeFullScreen();
          }}
          onPictureTaken={(dataUri, key, closeModal) => {
            const file = base64ToFile(dataUri, `${v4()}.png`);
            handleFileUpload(file, key);
            if (closeModal) {
              closeFullScreen();
            }
          }}
        />
      </div>

      <WizardButtons
        validatorFunction={validator}
        trackingEvent="tool-submit"
        trackingInfo={TAGMANAGER_TOOL_ARGS({
          event: "submit",
          toolStep: "picture",
          toolStepNumber: "1",
          inspectionPartTwo: true,
        })}
        trackingCommerce={TAGMANAGER_COMMERCE_ARGS({
          carConfigCode: inspection.key,
        })}
      />
    </div>
  );
};

export default Pictures;
