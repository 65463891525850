import moment from "moment";
import numeral from "numeral";
import {forEach, replace, keys} from "lodash";

export const getUrlParams = (params_string) => {
    const vars = {};
    params_string.replace(/[?&]+([^=&]+)=([^&]*)/gi, (m, key, value) => {
        vars[key] = value;
    });
    return vars;
};

export const findSelectOption = (options, value) => {
    return options.find((opt) => opt.value === value);
};

export const formatNumber = number => {
    // let nr = parseFloat(number);
    // return nr.toLocaleString(navigator.language, {maximumFractionDigits: 2})
    return `${numeral(number).format('0,0')}`;
};

export const formatPrice = (price, currency = "€") => {
    return `${currency}${formatNumber(price)}`
};

export const formatDate = (value, format = 'DD/MM/YYYY') => {
    if (value === null || value === undefined) {
        return ""
    }
    return moment(value).format(format);
};

export const getFirstRegistrationDateString = (year, month, day = 1) => {
    if (year && month) {
        return `${year}/${month}/${day}`
    } else {
        return undefined;
    }
};

export const getDealerFromUrl = (pathName) => {
    try {

        const parts = pathName.split('/');
        if (parts.length <= 0) {
            return 'default'
        }
        return parts[1]
    } catch (ex) {
        return 'default';
    }
};


// export const getCustomerKeyHostname = (url) => {
//   try {
//       const parts = url.split('.');
//       if(parts.length === 3) {
//           const customerKey = parts[0];
//           if(customerKey !== 'selfcheck') {
//               return customerKey
//           }
//       }
//       return 'default';
//   }
//   catch (ex) {
//       return 'default';
//   }
// };


export const base64ToFile = (dataUrl, fileName) => {
    let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, {type: mime});
};

export const convertYesNoToBoolean = (value) => {
    return (value === 'yes') ? true : false
};

export const formatFirstRegistration = (year, month) => {
    if (month && year) {
        return `${zeroPad(month, 2)}/${year}`
    } else if (year && !month) {
        return `${year}`;
    } else {
        return ''
    }
};

function zeroPad(num, places) {
    let zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

export const formatDateString = (dateString) => {
    if (dateString && dateString === "") return '';

    const date = moment(dateString);
    return date.format("DD/MM/YYYY");
};


export const getBackendUrl = (location) => {
    if (location.hostname === 'localhost') {
        return 'http://localhost:8000'
    } else {
        return location.origin;
    }
};

const contentFormatFields = {
    'dealer_name': 'dealer_name',
    'dealer_key': 'dealer_key',
    'dealer_email': 'dealer_email',
    'dealer_telephone': 'dealer_telephone',
    'dealer_address': 'dealer_address',
    'dealer_postal': 'dealer_postal',
    'dealer_city': 'dealer_city',
}


const getValueForField = (field, data) => {
    switch(field) {
        case contentFormatFields.dealer_name:
            return (data.dealer) ? data.dealer.name : "";
        case contentFormatFields.dealer_key:
            return (data.dealer) ? data.dealer.key : "";
        case contentFormatFields.dealer_email:
        case contentFormatFields["dealer.email"]:
            return (data.dealer) ? data.dealer.email : "";
        case contentFormatFields.dealer_telephone:
        case contentFormatFields["dealer.telephone"]:
            return (data.dealer) ? data.dealer.telephone : "";
        case contentFormatFields.dealer_address:
            return (data.dealer) ? data.dealer.address : "";
        case contentFormatFields.dealer_postal:
            return (data.dealer) ? data.dealer.postal : "";
        case contentFormatFields.dealer_city:
            return (data.dealer) ? data.dealer.city : "";
        default:
            return ""
    }
};

export const replaceAll = (source, search, replacement) => {
    if(source === undefined) {
        return source
    }

    //return source.split(search).join(replacement)
    return replace(source ,new RegExp(search, 'g'),replacement);

   // return source.replace(new RegExp(search, 'g'), replacement);
};


export const parseHtml = (html, data) => {
    const availableFields = keys(contentFormatFields)

    let result = html;
    forEach(availableFields, (field) => {
        result = replaceAll(result,`{{${field}}}`, getValueForField(field, data));
    });

    return result;
};

export const parseUrl = (url, language) => {
    if(url.includes('{{language}}') ) {
        return url.replace('{{language}}', language)
    }

    return url
}

export const isBrowserSupported = () => {
    const {detect} = require('detect-browser');
    const browser = detect();
    const name = browser.name;

    if (name === 'ie') {
        return false;
    } else {
        return true;
    }


    let supported;
    switch (name) {
        case 'chrome':
        case 'firefox':
        case 'edge':
        case 'safari':
        case 'ios':
        case 'crios':
            supported = true;
            break;
        default:
            supported = false;
    }
    return supported;
};
