import React from "react"
import tailwindConfig from '../../tailwind.config.js';
import resolveConfig from 'tailwindcss/resolveConfig';

const fullConfig = resolveConfig(tailwindConfig);

const LoadingIndicator = ({color, small, extraSmall, extraClasses}) => {
    let classes = small ? "small-loader" : "loader";
    classes = extraSmall ? "extra-small" : classes;
    return (
        <div className={classes}>
            <div className="inner one " style={{borderColor: color !== null ? color :  fullConfig.theme.colors.color_one}}/>
            <div className="inner two " style={{borderColor: color !== null ? color :  fullConfig.theme.colors.color_one}}/>
            <div className="inner three " style={{borderColor: color !== null ? color :  fullConfig.theme.colors.color_one}}/>
        </div>
    )
};

LoadingIndicator.defaultProps = {
    color: null,
    small: false,
    extraSmall: false
};

export default LoadingIndicator
