import {v4} from "uuid";

const LOCAL_STORAGE_KEY = 'selfcheck';
let dealer_key;


const get_inspection_key = (key) => {
    return `${LOCAL_STORAGE_KEY}_inspection_${key}`;
};

export const inspectionFromStorage = (key) => {
    const fromStorage = JSON.parse(localStorage.getItem(get_inspection_key(key)));

    if (fromStorage) {
        return {
            ...fromStorage,
        }
    } else {
        return undefined
    }
};

export const inspectionToStorage = (data) => {
    const key = v4();
    const {inspection, step} = data;
    localStorage.setItem(get_inspection_key(key), JSON.stringify({
        inspection: {
            guid: inspection.guid,
            bodyType: inspection.body,
            firstRegistrationMonth: inspection.firstRegistrationMonth,
            firstRegistrationYear: inspection.firstRegistrationYear,
            fuel: inspection.fuel,
            kw: inspection.kw,
            engineRange: inspection.engineRange,
            licensePlate: inspection.licensePlate,
            vin: inspection.vin,
            make: inspection.make,
            mileage: inspection.mileage,
            model: inspection.model,
            transmission: inspection.transmission,
            purchase: inspection.purchase,
            //contact: inspection.contact,
            // dealer: inspection.dealer,
        },
        step: step,
    }));
    return key;
};


export const removeInspectionFromStorage = (key) => {
    removeFromStorage(get_inspection_key(key));
};

export const removeFromStorage = (key) => {
    try {
        localStorage.removeItem(key);
    } catch (error) {
        localStorage.clear();
    }
};




export const clearVehicleStorage = () => {
    for (let [key, value] of Object.entries(localStorage)) {
        if (key.startsWith(get_inspection_key(""))) {
            removeFromStorage(key)
        }
    }
};
