import React, {useEffect, useRef, useState} from 'react';
import Calendar from "react-calendar";
import moment from 'moment';
import './DatePicker.css';
import {useOnClickOutside} from "../../hooks/useOnClickOutside";
import {formatDate} from "../../utils";


const DatePicker = ({name, disabled, placeholder, style, value, onChange, hasFocus, hasError,  activeStartDate, minDate, maxDate, calendarLocation, dateDisplayFormat}) => {
    const inputRef = useRef(null);
    const calendarRef = useRef(null);
    const [inputHasFocus, setInputHasFocus] = useState(hasFocus);
    const [containerWidth, setContainerWidth] = useState(0);
    const [pickerVisible, setPickerVisible] = useState(false);
    useOnClickOutside(calendarRef, () => setPickerVisible(false));

    const valueString = formatDate(value, dateDisplayFormat);


    useEffect(() => {
        if (hasFocus) {
            inputRef.current.focus();
            setPickerVisible(true);
        }

    }, [hasFocus, inputHasFocus]);

    useEffect(() => {
        setContainerWidth(inputRef.current ? inputRef.current.offsetWidth : 0);
    }, []);

    const handleKeyDown = (e) => {
        if (e.keyCode === 27) {
            setPickerVisible(false)
        }
    };

    const handleChangeDate = (date) => {
        const result = moment(date);
        const resultValue = {
            date: result,
            year: result.year(),
            month: result.month(),
            day: result.day(),
            fullString: result.format('YYYY-MM-DD')
        };
        onChange(resultValue);
        setPickerVisible(false);
    };

    const calcPosition = (location, width) => {
        switch (location) {
            case 'left':
                return {left: 0};
            case 'right':
                return {right: 0};
            default:
                return {left: `calc(-150px + ${width / 2}px)`}
        }
    }

    return (
        <div className="w-full relative" onKeyDown={handleKeyDown} onClick={(e) => {
            e.stopPropagation();
            setPickerVisible(true);
        }} >
            <div className={`flex relative appearance-none block w-full bg-white border rounded-lg py-3 px-4 cursor-pointer ${hasError ? 'border-color_error' : 'border-color_four'}`} style={{minHeight: 45}}>
                <input type="text"
                       onFocus={() => setInputHasFocus(true)}
                       onBlur={() => setInputHasFocus(false)}
                       ref={inputRef}
                       value={valueString}
                       name={name}
                       placeholder={placeholder}
                       disabled={disabled}
                       readOnly={true}
                       className="w-full bg-white  focus:outline-none cursor-pointer text-14"
                       style={style}/>

                <div className="flex items-center justify-center" style={{width: 20}}>
                    <i className="fal fa-calendar-alt  text-14 text-color_one"/>
                </div>
            </div>

            {pickerVisible &&
            <div className={`flex absolute flex-row date-picker date-picker-${calendarLocation}  border-white `}
                 style={{width: 350, marginTop: 10, ...calcPosition(calendarLocation, containerWidth)}}
                 ref={calendarRef} onKeyDown={handleKeyDown}
            >
                <Calendar
                    onChange={handleChangeDate}
                    value={(value && value !== "") ? new Date(value) : undefined}
                    format={"dd-MM-y"}
                    maxDate={maxDate}
                    minDate={minDate}

                />
            </div>}


        </div>
    )
};

DatePicker.defaultProps = {
    required: false,
    disabled: false,
    placeholder: "",
    hasError: false,
    className: "",
    style: {},
    activeStartDate: new Date(),
    minDate: undefined,
    maxDate: undefined,
    calendarLocation: 'center',  // left | right | center,
    dateDisplayFormat: 'DD/MM/YYYY',
};


export default DatePicker;
