import {v4} from "uuid";
import ReactGA from 'react-ga';

const USER_QUERY = `query User($dealer: String!) {
  user(dealer: $dealer) {
    id
    firstName
    lastName   
    email
  } 
}`;

const USER_QUERY_BY_CODE = `query UserByKey($dealer: String!, $code: String!) {
  userByKey(dealer: $dealer, code: $code) {
    id
    firstName
    lastName   
    email
  } 
}`;


export const session_default = {
    id: v4(),
    authenticated: false,
    user: {
        id: undefined,
        firstName: undefined,
        lastName: undefined,
        email: undefined,
    }
};

export async function initSession(dealer) {
    const user = await fetchUserData(dealer);

    if (user !== undefined) {

        ReactGA.set({
            userId: user.id,
        })

        return {
            ...session_default,
            authenticated: true,
            user: {
                id: user.id,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
            }
        }
    } else {
        return session_default;
    }
}

export const resetSessionToDefault = () => {
    return session_default
};

export const isAuthenticatedPending = (session) => {
    return session === undefined;
}

export const isAuthenticated = (session) => {
    return session && session.authenticated;
};

export const updateSession = (user, session) => {
    return {
        ...session,
        authenticated: true,
        user: {
            id: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
        }
    };
}

async function fetchUserInfo(dealerKey) {
    return fetch('/graphql', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Dealer': dealerKey},
        body: JSON.stringify({query: USER_QUERY, variables: {dealer: dealerKey}})
    })
        .then(resp => resp.json())
        .then((result) => {
            const {user} = result.data;
            if (user) {
                return {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                }
            } else {
                return undefined;
            }
        })
        .catch(err => {
            return undefined;
        })
};


async function fetchUserInfoByKey(dealerKey, code) {
    return fetch('/graphql', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Dealer': dealerKey},
        body: JSON.stringify({query: USER_QUERY_BY_CODE, variables: {dealer: dealerKey, code: code}})
    })
        .then(resp => resp.json())
        .then((result) => {
            const {userByKey} = result.data;
            if (userByKey) {
                return {
                    id: userByKey.id,
                    firstName: userByKey.firstName,
                    lastName: userByKey.lastName,
                    email: userByKey.email,
                }
            } else {
                return undefined;
            }
        })
        .catch(err => {
            return undefined;
        })
};

export async function fetchUserData(dealer) {
    return fetchUserInfo(dealer,)
}

export async function fetchUserDataByCode(dealer, code) {
    return fetchUserInfoByKey(dealer, code)
}

