import React, { useContext, useState, useEffect } from "react";
import StatusGeneral from "../components/StatusGeneral";
import { formatPrice } from "../../../utils";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import SelectBox from "../../input/SelectBox";
import { keys } from "lodash";
import { LoadingIndicator } from "../../indicators";
import ContentPopup from "../../legal/ContentPopup";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_PAGE_ARGS,
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../../tagManager";

const ProposedPending = ({ inspection, onAccept, loading }) => {
  const { takeOver, dealerConfig } = useContext(DataContext);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const { i18n } = useTranslation();

  useEffect(() => {
    analyticstracker().trackImpression("page-impression");
  }, []);

  const tagInfo = TAGMANAGER_PAGE_ARGS({
      pageName: "car-quotation/valuation-2",
      section: "valuation-2-proposal-received",
      language: i18n.language,
  });

  const validate = () => {
    let errors = {};

    if (!termsAccepted) {
      errors["terms"] = t("Please accept the terms convention terms");
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
        event: "error",
        toolStep: "thank-you",
        toolStepNumber: "8",
        errorType: "user-error",
        errorMessage: errors["terms"],
        inspectionPartThree: true,
      });
      let tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({
        carConfigCode: inspection.key,
      });
      let event = {
        event: "tool-error",
        info: JSON.parse(errorTrackingInfo),
        commerce: JSON.parse(tagArgsCommerce),
      };
      aTrack.trackEvent(event);
    }

    setErrors(errors);

    return keys(errors).length <= 0;
  };

  return (
    <React.Fragment 
>
      <StatusGeneral title={t("Your takeover price is here!")} />

      <div
        className="text-color_three mb-8 mt-8 leading-6 md:w-3/4"
        data-tracking-event="page-impression"
        data-tracking-info={tagInfo}
      >
        <div className="flex flex-col items-center justify-center mb-6 bg-color_five rounded-lg p-6">
          <div className="text-bold mb-2">
            {t("Based on the information you provided")}:
          </div>
          <div className="text-bold text-color_one text-24 pt-4 pb-4">
            {formatPrice(takeOver.proposedAmount)}{" "}
            <span className="text-12">{t("incl VAT")}</span>
          </div>

          {dealerConfig.transport && (
            <div>
              <i className="fal fa-check font-bold font-color_one mr-1" />{" "}
              <span className="font-bold font-color_one">
                {t("Free car pickup included.")}
              </span>{" "}
              {t("Everywhere in Belgium.")}
            </div>
          )}
        </div>

        <div className="flex items-center mb-5 tracking-wide">
          <SelectBox
            description=""
            selected={termsAccepted}
            onChange={(value) => setTermsAccepted(value)}
          />
          <div
            className={`${
              errors["terms"] ? "text-color_error" : "text-color_three"
            } text-14  ml-5`}
            dangerouslySetInnerHTML={{
              __html: t(
                "I agree to the {{styling}} convention terms {{stylingEnd}}",
                {
                  styling: '<span class="underline cursor-pointer">',
                  stylingEnd: "</span />",
                  interpolation: { escapeValue: false },
                }
              ),
            }}
            onClick={() => setPrivacyModalVisible(true)}
          ></div>
        </div>

        <div className="md:flex md:justify-center w-full md:items-center">
          <div
            className="flex rounded-full items-center justify-center text-center font-bold cursor-pointer py-3 px-5 text-14 tracking-widest bg-color_one text-white uppercase"
            onClick={() => {
              console.log('onClick')
              if (validate()) {
                onAccept();
              }
            }}
            style={{ minWidth: 205, minHeight: 45 }}
          >
            {loading && (
              <div className="flex items-center">
                <LoadingIndicator extraSmall color="white" />
              </div>
            )}
            {!loading && <div>{t("Accept offer")}</div>}
          </div>
        </div>
      </div>

      {privacyModalVisible && (
        <ContentPopup
          type="convention_terms"
          onClose={() => setPrivacyModalVisible(false)}
        />
      )}
    </React.Fragment>
  );
};

export default ProposedPending;
