import React, {useContext, useEffect} from 'react';
import {wizardSteps} from "./inspectionWizardConfig";
import DataContext from "../../data/context";
import StepHeader from "./StepHeader";
import StepIndicator from "./StepIndicator";
import {useHeight} from "../../hooks/useHeight";
import {StickyElements} from "../../data/constants";
import ErrorSummaryModal from "../ErrorSummaryModal";
import StepTitle from "./StepTitle";
import { Offline, Online } from "react-detect-offline";

const InspectionWizard = () => {
    const {inspectionWizardStep} = useContext(DataContext);
    const topSpace = useHeight([StickyElements.header, StickyElements.currentPrice]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [inspectionWizardStep]);

    const stepData = wizardSteps[inspectionWizardStep];

    return (
      <div className="w-full">
        {/*<CurrentValuation />*/}

        <StepTitle />

        <div className="sticky z-10 md:hidden" style={{ top: topSpace }}>
          <StepIndicator />
        </div>

        <div className="w-full md:flex md:justify-center">
          <div
            className="md:flex md:w-full md:px-8 lg:px-0 lg:w-2/3 md:justify-center"
            style={{ maxWidth: 1024 }}
          >
            <div className="hidden md:block bg-white md:w-2/5 md:my-20">
              <div
                className={`flex flex-col bg-color_five md:rounded-lg relative md:pt-10 sticky`}
                style={{
                  top:
                    topSpace && topSpace > 0 ? `calc(${topSpace}px + 2rem)` : 0,
                }}
              >
                <StepIndicator />
                <StepHeader />
              </div>
            </div>

            <div className="md:hidden bg-white md:w-2/5 md:mt-5">
              <StepHeader />
            </div>

            <div className="md:w-3/5 mt-5 lg:mt-10 mb-12">
              <Online> {stepData.component}</Online>
              <Offline>
                    <div className="ml-8 mr-8 mt-0 lg:mt-10">
                        <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
                            {'You are offline, please reconnect to continue the process'}
                        </div>
                    </div>
              </Offline>
            </div>

            <ErrorSummaryModal />

            {/*<pre>{JSON.stringify(inspection, null, 2)}}</pre>*/}
          </div>
        </div>
      </div>
    );

};

export default InspectionWizard;
