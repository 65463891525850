import React, {useContext, useEffect, useState} from 'react';
import {StickyElements} from "../../data/constants";
import DataContext from "../../data/context";

const minHeight = 40;

const CurrentValuation = () => {
    const [topSpace, setTopSpace] = useState(0);
    const {inspectionWizardConfiguration, inspectionWizardStep} = useContext(DataContext);
    const currentStep = inspectionWizardConfiguration[inspectionWizardStep];

    useEffect(() => {
        const headerSelector = document.getElementsByClassName(StickyElements.header);
        if (headerSelector.length > 0) {
            const [head] = headerSelector;
            const headerHeight = head.offsetHeight;
            setTopSpace(headerHeight);
        }
    }, []);

    return (
        <div className={`${StickyElements.currentPrice} flex w-full bg-color_one text-white items-center justify-center font-bold sticky z-20`} style={{minHeight: minHeight, top: topSpace}}>
            {currentStep.title}
        </div>
    );
};

export default CurrentValuation;
