import {includes} from "lodash";

export const takeOverStates = {
    'INITIAL': 'initial',
    'PROPOSED': 'proposed',
    'ACCEPTED': 'accepted',
    'ARCHIVED': 'archived',
}

const initial_states_expert = ['INITIAL', 'BUFFER', 'CONFIRMED', 'SELLER'];
const proposed_states_expert = ['PROPOSED'];
const accepted_states_expert = ['ACCEPTED', 'ASSIGNED'];

export const getTakeOverState = (state) => {
    if (includes(initial_states_expert, state)) {
        return takeOverStates.INITIAL
    } else if (includes(proposed_states_expert, state)) {
        return takeOverStates.PROPOSED
    } else if (includes(accepted_states_expert, state)) {
        return takeOverStates.ACCEPTED
    } else {
        return takeOverStates.ARCHIVED
    }
};
