
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {DataContextProvider} from "./data/context";
import {ClientContext, GraphQLClient} from "graphql-hooks";
import * as Sentry from '@sentry/browser';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import numeral from "numeral";
import "numeral/locales";
import {resetSessionToDefault} from "./data/session";
import {getDealerFromUrl, isBrowserSupported} from "./utils";
import i18n from './i18n'
import {hotjar} from 'react-hotjar';
import ReactGA from 'react-ga';
import BrowserNotSupported from "./components/not-supported/BrowserNotSupported";

/*eslint-disable no-unused-vars*/
import analyticsTransQA from 'analytics-transQA';
import analyticsTransGTM from 'analytics-transGTM';
/*eslint-enable no-unused-vars*/

numeral.locale('nl-be');

const browserSupported = isBrowserSupported();
if (!browserSupported) {
    ReactDOM.render(
        <React.StrictMode>
            <div className="font-body bg-gray-100">
                <BrowserNotSupported/>
            </div>

        </React.StrictMode>,
        document.getElementById('root')
    );
}

const client = new GraphQLClient({
    url: '/graphql',
    credentials: 'same-origin',
    headers: {'dealer': getDealerFromUrl(window.location.pathname), 'Accept-Language': i18n.language,},
    onError: ({operation, result}) => {
        if (result.error && result.error.graphQLErrors) {
            const error = result.error.graphQLErrors[0];
            if (error.code === "403") {
                resetSessionToDefault();
                window.location.reload()
            }
        }
    }
});

hotjar.initialize(2042902, 6);
Sentry.init({dsn: "https://94af3128521148e6abec9c7d387b5c0f@apm.autralis.com/13"});
ReactGA.initialize("UA-24263039-10"); //tracking-ID

ReactDOM.render(
    <React.StrictMode>
        <div className='flex flex-col flex-1 flex-auto font-body notranslate'>
            <ClientContext.Provider value={client}>
                <DataContextProvider>
                    <GoogleReCaptchaProvider reCaptchaKey="6Le-26QZAAAAAHdJbf2doHW-guAGSGVz9B4p5I89">
                        <App/>
                    </GoogleReCaptchaProvider>
                </DataContextProvider>
            </ClientContext.Provider>
        </div>

    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
