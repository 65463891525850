import {Redirect, Route} from "react-router-dom";
import React, {useContext} from "react";
import {getDealerFromUrl} from "../utils";
import DataContext from "../data/context";
import LoadingIndicator from "../components/indicators/LoadingIndicator";

const PrivateDealerRoute = ({children, ...rest}) => {
    const {dealerConfig, dealerKey, session} = useContext(DataContext);

    if(session === undefined){
        return <LoadingIndicator />
    }

    if (!dealerConfig.account) {
        return <Redirect to={`/${dealerKey}`}/>
    }

    return (
        <Route {...rest}
               render={({location}) =>
                   (session.authenticated) ? (
                       children
                   ) : (
                       <Redirect
                           to={{
                               pathname: `/${getDealerFromUrl(window.location.pathname)}/login`,
                               state: {from: location}
                           }}
                       />
                   )
               }
        />
    );
};

export default PrivateDealerRoute;
