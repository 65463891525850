import React from 'react';

const Microsoft = ({onClick}) => {
    return (
            <div className="flex p-4 cursor-pointer outline-none rounded-lg" style={{backgroundColor: '#2672EC', height: 45}} onClick={onClick}>

                <div className="flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width={25} height={25} viewBox="0 0 480 480">
                        <g>
                            <path
                                d="M0.176,224L0.001,67.963l192-26.072V224H0.176z M224.001,37.241L479.937,0v224H224.001V37.241z M479.999,256l-0.062,224   l-255.936-36.008V256H479.999z M192.001,439.918L0.157,413.621L0.147,256h191.854V439.918z"
                                fill={'white'}/>
                        </g>
                    </svg>
                </div>
            </div>
    );
};

export default Microsoft;
