import React from 'react';
import {useTranslation} from "react-i18next";
import StatusGeneral from "../components/StatusGeneral";

const Error = () => {
    const {t} = useTranslation();

    return (
        <React.Fragment>
            <StatusGeneral title={t("Something went wrong...")} statusTableVisible={false}/>

            <div className="md:w-3/4 mt-12">
                <div className="flex bg-color_four rounded-lg p-6">
                    <div className="flex items-center justify-center"><i className="fal fa-user-headset mr-8 text-44 text-color_three"/></div>
                    <div className="font-bold text-color_one text-center" style={{width: 300}}>
                        <div className="mb-2"> {t("Please contact the helpdesk")}:</div>
                        <div className="font-bold text-color_one text-center text-18" style={{width: 300}}>+32 (0)9 320 00 23</div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};

export default Error;
