import React, {useContext} from 'react';
import {map, orderBy} from "lodash";
import DataContext from "../../../data/context";
import {getTakeOverState, takeOverStates} from "../constants";
import {formatDateString} from "../../../utils";
import moment from 'moment';
import {useTranslation} from "react-i18next";



const generateRows = (takeOver, status, translations) => {
    let rows = [];

    const initialRow = {date: moment(takeOver.createdDate), text: translations['In treatment']};
    const proposedRow = {date: moment(takeOver.proposedDate), text: translations[`Value calculated`]};
    // const proposedRowWithAmount = {date: moment(takeOver.proposedDate), text: translations[`Voorstel tot overname ${formatPrice(takeOver.proposedAmount)}`]};
    const conventionSendRow = {date: moment(takeOver.acceptedDate).subtract('5', 'seconds'), text: translations[`Convention signed and sent`]};
    const acceptedRow = {date: moment(takeOver.acceptedDate), text: translations['Proposal accepted']};
    const declinedRow = {date:  moment(takeOver.proposedDate).add(7, 'days'), text: translations['Proposal expired']};

    if (status === takeOverStates.INITIAL) {
        rows.push(initialRow)
    }

    if (status === takeOverStates.PROPOSED) {
        rows.push(initialRow);
        rows.push(proposedRow);

        if(takeOver.conventionUploaded) {
            rows.push(conventionSendRow);
        }
    }

    if (status === takeOverStates.ACCEPTED) {
        rows.push(initialRow);
        rows.push(proposedRow);
        rows.push(conventionSendRow);
        rows.push(acceptedRow);
    }

    if (status === takeOverStates.ARCHIVED) {
        rows.push(initialRow);

        if (takeOver.proposed) {
            rows.push(proposedRow);
        }

        if(takeOver.conventionSend){
            rows.push(conventionSendRow);
        }

        if (takeOver.accepted) {
            rows.push(acceptedRow);
        } else {
            rows.push(declinedRow)
        }
    }
    return rows
};


const StatusTable = () => {
    const {takeOver} = useContext(DataContext);
    const status = getTakeOverState(takeOver.state);

    const {t} = useTranslation();
    const translations = {
        'In treatment': t("In treatment"),
        'Value calculated': t("Value calculated"),
        'Convention signed and sent': t("Convention signed and sent"),
        'Proposal accepted': t("Proposal accepted"),
        'Proposal expired': t("Proposal expired"),

    };
    const tableRows = generateRows(takeOver, status, translations);

    return (
        <table className="table-fixed mt-6 mb-6">
            <thead>
            <tr>
                <th className="px-4 py-2 font-normal w-1/4 text-left">{t('Date')}</th>
                <th className="px-4 py-2 font-normal w-1/2 text-left">{t('State')}</th>
            </tr>
            </thead>
            <tbody>
            {map(orderBy(tableRows, ['date'], ['desc']), (r, i) => {
                return (
                    <tr key={i}>
                        <td className="border px-4 py-2">{formatDateString(r.date)}</td>
                        <td className={`border px-4 py-2   ${(i === 0) ? 'font-bold text-color_one' : ''}`}>{r.text}</td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    );
};

export default StatusTable;
