import React, {useEffect, memo, useContext} from 'react';
import DataContext from "../data/context";
import {parseHtml} from "../utils";

const HtmlBlock = memo(props => {
    const {dealer} = useContext(DataContext);

    useEffect(() => {
        if (props.script && props.script !== "") {
            const executeScript = new Function(props.script);
            executeScript();
        }
    });

    const classes = props.classNames && props.classNames !== ""
    return (
        <div className={classes ? props.classNames : ""} dangerouslySetInnerHTML={{__html: parseHtml(props.html, {dealer: dealer})}}/>
    );
});

export default HtmlBlock;
