import React, {useContext, useEffect} from 'react';
import DataContext from "../../data/context";
import {useManualQuery} from "graphql-hooks";
import {useHistory} from "react-router-dom";


export const InspectionsForUser = `
  query InspectionsForUser($dealer: String!)  {
    inspectionsForUser(dealer: $dealer) {
        id
        customer
        userId        
        make
        model
        fuel
        bodyType
        transmission
        licensePlate
        mileage
    }
  }
`;


const Account = ({confirmation: confirmationFromPops = undefined}) => {
    const {dealerKey} = useContext(DataContext);
    const [fetchData, {data}] = useManualQuery(InspectionsForUser);
    let history = useHistory();

    useEffect(() => {
        fetchData({variables: {dealer: dealerKey}});
    }, [dealerKey]);

    return (
        <div>
            <h1>Hi, welcome to the account page</h1>

            <div> Your inspections:</div>
            {data && data.inspectionsForUser &&
            data.inspectionsForUser.map((inspection, i) => {

                return (
                    <div className="mt-6" key={i}>
                        <div>{inspection.licensePlate} - ({`${inspection.make} - ${inspection.model})`}</div>
                        <div>
                            <span className="underline cursor-pointer" onClick={() => history.push(`/${dealerKey}/valuation/${inspection.id}`)}>waardebepaling</span> - <span
                            className="underline cursor-pointer" onClick={() => history.push(`/${dealerKey}/inspection/${inspection.id}`)}>volledige inspectie</span>
                        </div>
                        <div>*********</div>
                    </div>
                )
            })
            }


        </div>
    );
};

export default Account;
