import React, {useContext, useEffect} from 'react';
import {useQuery} from "graphql-hooks";
import {useTranslation} from "react-i18next";
import LoadingIndicator from "../../indicators/LoadingIndicator";
import DataContext from "../../../data/context";
import Scrollbar from "../../Scrollbar";
import {parseHtml} from "../../../utils";

const contentQuery = `query Content($contentType: String! $dealerId: ID!) {
  content(contentType: $contentType, dealerId: $dealerId) {
     data     
  }
}`;


const Content = ({type}) => {
    const {dealer} = useContext(DataContext);
    const {loading, data, refetch} = useQuery(contentQuery, {variables: {contentType: type, dealerId: dealer.id}});
    const {i18n} = useTranslation();

    useEffect(() => {
        refetch();
    }, [refetch, i18n.language]);


    if (loading) {
        return <LoadingIndicator/>
    }

    if (data && data.content && data.content.data && data.content.data !== "") {
        return (
            <div className="flex flex-1">
                <Scrollbar>
                    <div dangerouslySetInnerHTML={{__html: parseHtml(data.content.data, {dealer: dealer})}}/>
                </Scrollbar>
            </div>
        );
    }

    return (
        <div>No content found</div>
    );
};

export default Content;
