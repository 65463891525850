import {getBackendUrl} from "../../utils";

export const getSocialAuthUrlForProvider = (provider, dealerKey, redirect=undefined) => {

    let url =  `${getBackendUrl(window.location)}/login/${provider}?dealer=${dealerKey}`;

    if(redirect) {
        url = `${url}&redirect_url=${redirect}`
    }

    return url;
}
