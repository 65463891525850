import React from 'react';

const ErrorIndicator = ({text, icon}) => {
    return (
        <div className="p-2">
            <div className="inline-flex justify-center items-center bg-white leading-none rounded-full p-3 text-red-600 border border-red-200 z-50" style={{minWidth: 300}}>
                {/*<span className="inline-flex bg-pink-600 text-white rounded-full h-6 px-3 justify-center items-center">Pink</span>*/}
                <i className={`${icon} text-14`}/>
                <span className="inline-flex px-2 text-16">{text}</span>
            </div>
        </div>
    );
};

ErrorIndicator.defaultProps = {
    text: "Er ging iets mis.",
    icon: "far fa-exclamation-triangle"
}

export default ErrorIndicator;
