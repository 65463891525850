import {useState, useEffect} from 'react';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {useTranslation} from "react-i18next";

const VALUATION_QUERY = `query Valuation ($key: String!) {
  valuation (key: $key) {
     minValue
    maxValue
    guideValue
  } 
}`;

export function useValuation(inspection) {
    const [loading, setLoading] = useState(true);
    const [hasValuation, setHasValuation] = useState(true);
    const [error, setError] = useState(null);
    const [valuation, setValuation] = useState(null);
    const {executeRecaptcha} = useGoogleReCaptcha();
    const {i18n} = useTranslation();

    useEffect(() => {
        const fetchValuation = async (inspection) => {
            if (!executeRecaptcha) {
                return;
            }

            const token = await executeRecaptcha("valuation");

            fetch('/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': i18n.language, 'Captcha': token},
                body: JSON.stringify({query: VALUATION_QUERY, variables: {key: inspection.key, captcha: token}})
            })
                .then(resp => resp.json())
                .then((result) => {
                    const {valuation} = result.data;
                    if (valuation) {
                        setValuation({
                            guide: valuation.guideValue,
                            min: valuation.minValue,
                            max: valuation.maxValue
                        });
                        setHasValuation(((valuation !== undefined || valuation !== null) && (valuation.guideValue !== undefined && valuation.guideValue !== null) && valuation.guideValue !== 0))
                        //setHasValuation(false)
                    } else {
                        setValuation({
                            guide: undefined,
                            min: undefined,
                            max: undefined,
                        });
                        setHasValuation(false)
                    }

                    setLoading(false);
                })
                .catch(err => {
                    setError("ERROR");
                    setLoading(false);
                    setHasValuation(false);
                })
        };

        if (inspection && inspection.key && inspection.userId >= 0) {
            fetchValuation(inspection)
        }

    }, [inspection.key, inspection.userId]);

    return {
        valuation,
        loading: loading,
        error,
        hasValuation,
    };
}
