import React from 'react';
import {Helmet} from "react-helmet";

const TestCookieBanner = () => {
    return (
        <div>
            <Helmet>
                <title>Test for cookiebanner</title>
                <script type="text/javascript" src="https://nexus.ensighten.com/dieteren/myway_stage/Bootstrap.js"></script>
            </Helmet>

            Test for cookie banner

        </div>
    );
};

export default TestCookieBanner;
