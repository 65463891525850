import React, { useContext } from "react";
import DataContext from "../../data/context";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import analyticstracker from "analyticstracker";
import {
  TAGMANAGER_TOOL_ARGS,
  TAGMANAGER_BUTTON_ARGS,
  TAGMANAGER_COMMERCE_ARGS,
} from "../../tagManager";

const WizardButtons = ({
  validatorFunction,
  onPrev,
  onNext,
  trackingEvent = undefined,
  trackingInfo = undefined,
}) => {
  const {
    vehicleWizardConfiguration,
    vehicleWizardStep,
    setNextVehicleDataStep,
    setPrevVehicleDataStep,
    inspectionReset,
    inspection,
  } = useContext(DataContext);
  const nextStep = vehicleWizardConfiguration[vehicleWizardStep + 1];

  let history = useHistory();
  const { t } = useTranslation();

  let text = "";
  if (nextStep === undefined && onNext === undefined) {
    text = t("Get valuation");
    trackingEvent = "tool-complete";
    trackingInfo = TAGMANAGER_TOOL_ARGS({
      event: "complete",
      toolStep: "thank-you",
      toolStepNumber: "12",
    });
    // Set submit event automatically on last step
  } else {
    text = t("Next");
  }

  //this function used to by async and validatorfunctie used to be awaited. Removed because of tracking problems and no reason to be async
  const handleNext = () => {
    const valid = validatorFunction();
    let event;
    if (valid) {
      let aTrack = analyticstracker();
      event = {
        event: trackingEvent,
        info: JSON.parse(trackingInfo),
        commerce: JSON.parse(
          TAGMANAGER_COMMERCE_ARGS({ carConfigCode: inspection.key })
        ),
      };
      aTrack.trackEvent(event);
      if (onNext && typeof onNext === "function") {
        onNext();
      } else {
        setNextVehicleDataStep();
      }
    }
  };

  const handlePrev = () => {
    if (onPrev && typeof onPrev === "function") {
      onPrev();
    } else {
      if (vehicleWizardStep >= 0) {
        setPrevVehicleDataStep();
      } else {
        history.goBack();
      }
    }
  };
  const trackReset = (buttonText) => {
    let aTrack = analyticstracker();
    let errorTrackingInfo = TAGMANAGER_BUTTON_ARGS({
      buttonName: "restart",
      buttonText,
    });
    let event = { event: "button-click", info: JSON.parse(errorTrackingInfo) };
    aTrack.trackEvent(event);
  };
  return (
    <React.Fragment>
      <div className="flex justify-between w-full ">
        <div
          className="flex rounded-full items-center justify-center border border-color_three text-color_three cursor-pointer "
          style={{ width: 45, height: 45 }}
          onClick={handlePrev}
        >
          <i className="fal fa-chevron-left font-bold text-14" />
        </div>

        <div
          className="btn-primary flex rounded-full uppercase bg-color_one text-white items-center justify-center text-center font-bold text-14 md:text-12 cursor-pointer pl-4 pr-4 tracking-wide md:w-3/5"
          style={{ height: 45, minWidth: 165 }}
          onClick={handleNext}
        >
          <div className="flex flex-1 justify-center items-center">{text}</div>
          <div style={{ width: 20 }}>
            <i className="fal fa-chevron-right font-bold" />
          </div>
        </div>
      </div>

      <div
        className="flex items-center justify-center underline mt-4 cursor-pointer"
        onClick={() => {
          trackReset(t("Start again"));
          inspectionReset();
        }}
      >
        {t("Start again")}{" "}
      </div>
    </React.Fragment>
  );
};

export default WizardButtons;
