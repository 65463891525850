import React, {useContext, useEffect} from 'react';
import WizardButtons from "./WizardButtons";
import CurrentValuation from "../valuation/CurrentValuation";
import {map} from "lodash";
import {useTranslation} from "react-i18next";
import DataContext from "../../data/context";
import HtmlBlock from "../HtmlBlock";
import analyticstracker from "analyticstracker";
import {TAGMANAGER_PAGE_ARGS} from '../../tagManager';

const InspectionStart = () => {
    const {inspectionWizardConfiguration, dealerConfig} = useContext(DataContext);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    });

    useEffect(() => {
        let aTrack = analyticstracker();
        aTrack.trackImpression("page-impression");
      }, []);

    const tagInfo = TAGMANAGER_PAGE_ARGS({
        section: "inspection-2",
        language: i18n.language,
    })

    const validator = () => {
        return true;
    };


    const rootClass = (dealerConfig && dealerConfig.inspectionStart) ? " " : "ml-10 mr-10 mt-6 md:mt-20 md:w-1/2";

    // info icoon 36/ vous devenez le vendeur 14/ conseils ... 36 /lightbulb 24/ tekst 12

    return (
        <div className="flex flex-1 w-full"
             data-tracking-event="page-impression"
             data-tracking-info={tagInfo}>
            <div className="w-full  md:flex md:flex-col  md:items-center ">

                {dealerConfig.valuationAverage &&
                <CurrentValuation/>}

                {dealerConfig && dealerConfig.inspectionStart && dealerConfig.inspectionStart.html && dealerConfig.inspectionStart.html !== "" &&
                <HtmlBlock html={dealerConfig.inspectionStart.html} script={dealerConfig.inspectionStart.script}/>}


                <div className={rootClass}>

                    <div className="text-center text-34 font-bold text-color_one tracking-wider">{t('Inspection')}</div>

                    <p className="mt-6 text-center leading-6">{t("We'll guide you through a professional car inspection in only 5 steps. With the information provided we'll easily calculate a correct takeover price for your car.")}</p>

                    <div className="flex flex-1 justify-center mt-10 mb-10">
                        <div className="border-l-2 border-color_one">
                            {map(inspectionWizardConfiguration, (step, i) => {
                                return (
                                    <div key={`step${i}`} className={`bg-white ${(i !== inspectionWizardConfiguration.length - 1) ? 'mb-6 md:mb-12' : ''}`}>
                                        <i className={`${step.icon} border-2 rounded-full border-color_one p-2 bg-white text-color_one `} style={{marginLeft: -20, fontSize: 18}}/>
                                        <span className="font-md font-bold ml-4 text-color_one">{i + 1}. {step.title}</span>
                                    </div>
                                )
                            })}
                        </div>
                    </div>

                    <div className="flex w-full justify-center mb-12">
                        <WizardButtons validatorFunction={validator}/>
                    </div>

                </div>

            </div>
        </div>
    );
};

export default InspectionStart;
