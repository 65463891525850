import React, {useContext, useEffect, useState} from "react";
import Cookies from "js-cookie";
import "./CookieBanner.css";
import Switch from "react-switch";
import DataContext from "../../data/context";
import {useTranslation} from "react-i18next";
import {getDealerFromUrl} from "../../utils";

import {useHistory} from "react-router-dom";

const GDPR_ANALYTICS = "GDPR_ANALYTICS";

const CookieBanner = ({visible, context}) => {
    const {t} = useTranslation();
    let history = useHistory();

    const [isVisible, setVisible] = useState(visible);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const cookieValue = Cookies.get(GDPR_ANALYTICS);
    const [analyticsEnabled, setAnalyticsEnabled] = useState(
        cookieValue ? (cookieValue === "false" ? false : true) : true
    );
    const {setCookiesAccepted} = useContext(DataContext);

    useEffect(() => {
        setVisible(visible);
    }, [visible]);

    const setAnalyticsCookie = (value) => {
        Cookies.set(GDPR_ANALYTICS, value);
    };

    const handleAccept = () => {
        setVisible(false);
        setAnalyticsCookie(analyticsEnabled);
        setCookiesAccepted(true);
    };

    const handleSave = () => {
        setAnalyticsCookie(analyticsEnabled);
        setSettingsVisible(false);
    };

    const handlePrivacyClick = () => {
        history.push(`/${getDealerFromUrl(window.location.pathname)}/privacy`)

    };

    const handleSettings = () => {
        setSettingsVisible(true);
    };

    if (!visible) {
        return null;
    }

    return (

        <div style={{zIndex: 99999}}
             className="cookies-banner flex flex-col py-5 px-5 fixed left-0 right-0 banner-shadow bg-white bottom-0">

            {!settingsVisible && (
                <React.Fragment>
                    <div className="flex flex-1 flex-col md:flex-row ">
                        <div className="flex flex-1 w-full md:w-1/2 lg:w-3/5 items-center">
                            <div className="flex items-center justify-center mr-4 md:block">
                                <i className="fad fa-cookie-bite text-4xl "/>
                            </div>

                            <div className="flex flex-col text-sm font-light cookies-text">
                                <div>
                                    {t("This website uses cookies to improve your experience.")}
                                </div>
                                <div>{t("Press")}
                                    <span onClick={handlePrivacyClick} className="underline ml-1 mr-1 cursor-pointer outline-none">{t("here")}</span>
                                    <span>{t("to see how cookies work and why we use them")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full md:w-1/2 lg:w-2/5 flex-col md:flex-row items-end justify-between mt-4 md:mt-0">
                            <button id="cookies-accept-button"
                                    onClick={handleAccept}
                                    style={{minWidth: 125}}
                                    className="flex w-full relative justify-center items-center h-10 bg-color_one  text-white text-md font-medium py-2 px-4 rounded-none text-center hover:text-gray-300 black-and-white outline-none mb-2 md:mb-0 "
                            >
                                <div className="flex items-center justify-center mr-2">
                                    {t("Accept")}
                                </div>
                            </button>

                            <button id="cookies-settings-button"
                                    className="flex w-full relative justify-center items-center h-10 bg-color_one  text-white text-md font-medium py-2 px-4 rounded-none text-center hover:text-gray-300 black-and-white  outline-none md:ml-4"
                                    style={{minWidth: 125}}
                                    onClick={handleSettings}
                            >
                                <div className="flex items-center justify-center mr-2">
                                    {t("Settings")}
                                </div>
                            </button>
                        </div>
                    </div>
                </React.Fragment>
            )}

            {settingsVisible && (
                <React.Fragment>
                    <div className="flex flex-1 flex-col md:flex-row ">
                        <div className="flex flex-col flex-1 w-full md-4/5">
                            <div className="flex w-4/5 mb-2">
                                <div className="mr-2">
                                    <Switch disabled={true}
                                            checked={true}
                                            onChange={() => null}
                                            width={40}
                                            height={20}
                                    />
                                </div>
                                <div className="cookies-text">
                                    <div className="font-bold text-sm">
                                        {t("Necessary cookies")}
                                    </div>
                                    <div className="text-xs">
                                        {t("Some cookies are required for basic functionalities. This website will not work correctly without them, therefore they're active by default and can not be turned off.")}
                                    </div>
                                </div>
                            </div>

                            <div className="flex w-4/5">
                                <div className="mr-2">
                                    <Switch onChange={(checked) => setAnalyticsEnabled(checked)}
                                            checked={analyticsEnabled}
                                            width={40}
                                            height={20}
                                    />
                                </div>
                                <div className="cookies-text">
                                    <div className="font-bold text-sm">
                                        {t("Analytical cookies")}
                                    </div>
                                    <div className="text-xs">
                                        {t("Analytical cookies help us to improve this website by collecting data and analysing behaviour")}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full md:w-1/5 flex-col md:flex-row items-center justify-center mt-2 md:mt-0">
                            <button id="cookies-save-button"
                                    onClick={handleSave}
                                    style={{minWidth: 125}}
                                    className={`flex w-full relative justify-center items-center h-10 bg-color_one  text-white text-md font-medium py-2 px-4 rounded-none text-center hover:text-gray-300 black-and-white outline-none mb-2 md:mb-0 `}
                            >
                                <div className="flex items-center justify-center mr-2">
                                    {t("Save")}
                                </div>
                            </button>
                        </div>
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default CookieBanner;
