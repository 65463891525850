import React, {useContext, useEffect} from 'react';
import DataContext from "../data/context";
import {useTranslation} from "react-i18next";
import {getDealerFromUrl} from "../utils";
import {Redirect} from "react-router-dom";
import LoadingIndicator from "./indicators/LoadingIndicator";
import HtmlBlock from "./HtmlBlock";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_PAGE_ARGS } from '../tagManager';

const Splash = () => {
    const {dealerKey, dealerConfig, fetchDealer, initialDataLoaded} = useContext(DataContext);
    const {i18n} = useTranslation();

    const tagInfo = TAGMANAGER_PAGE_ARGS({
        section: "home",
        language: i18n.language,
    })

    useEffect(() => {
        //only track event if there's actual html and there's no immediate redirect
        if (dealerConfig.home && dealerConfig.home.html && dealerConfig.home.html !== "") {
            let aTrack = analyticstracker();
            let errorTrackingInfo = TAGMANAGER_PAGE_ARGS({
                section: "home",
                language: i18n.language,
            })
            let event = { "event": "page-impression", "info": JSON.parse(errorTrackingInfo) };
            aTrack.trackEvent(event);
        }
      }, []);


     useEffect(() => {
         i18n.on('languageChanged', (lng) => {
             fetchDealer(getDealerFromUrl(window.location.pathname))
         })
     }, []);

    if (!initialDataLoaded) {
        return <LoadingIndicator/>
    }

    try {
        if (dealerConfig.home && dealerConfig.home.html && dealerConfig.home.html !== "") {
            return (
                <HtmlBlock
                html={dealerConfig.home.html} script={dealerConfig.home.script} classNames="flex flex-1"/>
            )

        }
    } catch (exception) {

    }

    return <Redirect to={{ pathname: `/${dealerKey}/inspection`, search: (window.location.search) ? window.location.search : '' }} />
};

export default Splash;
