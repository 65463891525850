
export const ValuationSteps = {
    home: 'home',
    result: 'result',
    wizard: 'wizard',
    contact: 'contact',
};

export const PictureTypes = {
    damage: 'damage',
    general: 'general',
}

export const StickyElements = {
    header: "header",
    currentPrice: "current-price"
}