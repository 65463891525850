import {useEffect, useState} from "react";

export const useOrientation = () => {
    const [orientation, setOrientation] = useState(undefined);
    useEffect(() => {
        const mql = window.matchMedia("(orientation: portrait)");
        if (mql.matches) {
            if (orientation !== 'portrait') {
                setOrientation('portrait');
            }
        } else {
            if (orientation !== 'landscape') {
                setOrientation('landscape');
            }
        }
        mql.addListener(listener);
        return () => {
            mql.removeListener(listener)
        }
    }, []);


    const listener = m => {
        if (m.matches) {
            if (orientation !== 'portrait') {
                setOrientation('portrait');
            }
        } else {
            if (orientation !== 'landscape') {
                setOrientation('landscape');
            }
        }
    }

    return {
        isLandscape: orientation === 'landscape',
        isPortrait: orientation === 'portrait'
    }
}
