import React, {useContext, useEffect} from 'react';
import Content from "./components/Content";
import DataContext from "../../data/context";
import {useTranslation} from "react-i18next";
import {parseUrl} from "../../utils";

const ContentPopup = ({type, onClose}) => {
    const {dealerConfig} = useContext(DataContext);
    const {i18n} = useTranslation();

    useEffect(() => {
        let url = undefined;
        if (type === 'privacy_policy' && dealerConfig.privacyUrl && dealerConfig.privacyUrl !== "") {
            url = parseUrl(dealerConfig.privacyUrl, i18n.language)
        } else if (type === 'convention_terms' && dealerConfig.termsAndConditionsUrl && dealerConfig.termsAndConditionsUrl !== "") {
            url = parseUrl(dealerConfig.privacyUrl, i18n.language);
        }

        if (url) {
            window.open(parseUrl(dealerConfig.privacyUrl, i18n.language))
            onClose()
        }
    }, []);

    const hide = ((type === 'privacy_policy' && dealerConfig.privacyUrl && dealerConfig.privacyUrl !== "") ||
        (type === 'convention_terms' && dealerConfig.termsAndConditionsUrl && dealerConfig.termsAndConditionsUrl !== ""));

    if (hide) {
        return null;
    }

    return (
        <div className="flex items-center justify-center fixed left-0 right-0 top-0 bottom-0 z-50" style={{background: "rgba(0, 0, 0, 0.6)"}}>
            <div className="p-5 flex flex-col border-2 rounded-lg bg-white overflow-hidden" style={{width: '75%', height: '75%'}}>


                <div className="flex items-end justify-end mb-6" onClick={onClose}>
                    <i className="fal fa-times text-24 cursor-pointer"/>
                </div>

                <div className="flex flex-1">
                    <Content type={type}/>
                </div>


            </div>
        </div>
    );
};

export default ContentPopup;
