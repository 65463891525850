/*
These function provide an easy way to generate the data that has to be send with the analytics packages
*/

export const TAGMANAGER_PAGE_ARGS = ({ section = "", language = "", pageName="" }) => {
  const fullUrl = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);
  const lma = searchParams.get("lma");
  const pageviewMode = lma ? "dealer" : "national";

  const hostname = window.location.hostname;

  let brand;
  if (hostname.includes("audiapprovedplus")) {
    brand = "audi";
  } else if (hostname.includes("myway")) {
    brand = "myway";
  } else {
    brand = "unknown";
  }

  return JSON.stringify({
    pageName: pageName || `car-quotation/${section}`,
    section: section,
    subSectionOne: "",
    subSectionTwo: "",
    pageUrl: fullUrl.split("?")[0],
    pageFullUrl: fullUrl,
    pageviewMode,
    pageType: "homepage",
    platform: "car-quotation",
    brand: brand, // audi or myway
    language: language,
    loginStatus: "not logged in",
    previousPageName: "",
    previousPageUrl: "",
    previousPageFullUrl: "",
    previousPageType: "",
    previousPlatform: "car-quotation",
    previousBrand: "",
    pageVersion: "",
    author: "Autralis",
    dealerGroupID: lma || "",
    dealerGroupName: "",
    dealerID: "",
    dealerLocation: "",
    dealerName: "",
  });
};

export const TAGMANAGER_COMMERCE_ARGS = ({
  carConfigCode = "",
}) => {
  return JSON.stringify({
    // Following fields are required to send with event but not relevantin our case
    carBrand: "",
    carModel: "",
    referenceId: "",
    carConfigCode: carConfigCode,
    carEquipmentLine: "",
    carEngineType: "",
    carEnginePower: "",
    carGearboxType: "",
    carExteriorColor: "",
    carInteriorColor: "",
    carOptionsList: "",
    carPrice: "",
    carTotalPrice: "",
    carYear: "",
    carMileage: "",
  });
};

export const TAGMANAGER_TOOL_ARGS = ({
  toolStep = "",
  toolStepOption = "",
  toolStepNumber = "",
  inspectionPartTwo = false,
  inspectionPartThree = false,
  errorType = "",
  errorMessage = "",
}) => {
  const hostname = window.location.hostname;
  let toolName;
  if (hostname.includes("audiapprovedplus")) {
    toolName = "aap-car-quotation";
  } else if (hostname.includes("myway")) {
    toolName = "myway-car-quotation";
  } else {
    toolName = "unknown";
  }

  //could use a rewrite, as they kept adding parts to the inspection
  if (inspectionPartTwo) {
    toolName = toolName + "-2";
  } else if (inspectionPartThree) {
    toolName = toolName + "-3";
  }

  return JSON.stringify({
    toolType: "request-form",
    toolName: toolName,
    toolStep: toolStep,
    toolStepNumber: toolStepNumber,
    toolStepOption: toolStepOption,
    leadID: "",
    requestType: "take-over",
    errorType: errorType || "",
    errorMessage: errorMessage || "",
  });
};

export const TAGMANAGER_BUTTON_ARGS = ({
  buttonName = "",
  buttonText = "",
}) => {
  return JSON.stringify({
    buttonType: "button",
    buttonCategory: "interaction",
    buttonName,
    buttonText,
    buttonTarget: "",
    buttonPosition: "",
  });
};
