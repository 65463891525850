import React, {useContext, useEffect} from 'react';
import Content from "./components/Content";
import DataContext from "../../data/context";

import {parseUrl} from "../../utils";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";


const Privacy = () => {
    const {dealer, dealerConfig} = useContext(DataContext);
    const { i18n } = useTranslation();
    let history = useHistory();

    useEffect(() => {
        if(dealerConfig.privacyUrl && dealerConfig.privacyUrl !== "") {
            window.open(parseUrl(dealerConfig.privacyUrl, i18n.language))
            history.goBack()
        }
    }, []);


    return (
        <div className="flex flex-1 md:justify-center md:mb-12 md:mt-12">
            <div className="flex flex-1 md:justify-center p-4 md:p-0" style={{maxWidth: 1024}}>
                <Content type="privacy_policy"/>
            </div>
        </div>
    );
};

export default Privacy;
