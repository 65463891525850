import React, {useContext} from 'react';
import DataContext from "../../data/context";

const StepIndicator = ({onInfoClicked}) => {
    const {vehicleWizardConfiguration, vehicleWizardStep} = useContext(DataContext);

    const percentage = ((vehicleWizardStep / vehicleWizardConfiguration.length)  * 100) + 1;
    const completed = percentage === 100;

    return (
        <div className="flex items-center justify-center bg-white border-t border-b border-color_four left-0 right-0 mx-0 lg:mx-12" style={{minHeight: 61}}>
            <div className="flex justify-between items-center mx-10 relative w-full">
                <div className="flex flex-row w-full">
                    <div className="flex" style={{width: 40}}>
                        <i className={`fal fa-car border-2 z-10 rounded-full p-2 bg-color_one text-white border-color_one`}
                           style={{fontSize: 18}}/>
                    </div>
                    <div className="flex w-full items-center justify-center relative">
                        <div className="border border-t-2 border-color_four flex flex-1 absolute left-0 right-0 "/>
                        <div className="border border-t-2 border-color_one flex flex-1 absolute left-0 right-0 " style={{width: `${percentage}%`}}/>
                        <i className="fas fa-info-circle text-color_one absolute text-20" style={{left: `${percentage}%`}} onClick={onInfoClicked}/>
                    </div>
                    <div className="flex" style={{width: 40}}>
                        <i className={`fal fa-tag border-2 z-10 rounded-full p-2 bg-white ${completed ? 'text-color_one border-color_one' : 'text-color_four border-color_four'}`}
                           style={{fontSize: 18}}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StepIndicator;
