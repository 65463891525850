import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../data/context";
import InspectionWizard from "./InspectionWizard";
import InspectionStart from "./InspectionStart";
import InspectionContact from "./InspectionContact";
import {Redirect, useParams} from "react-router-dom";
import {LoadingIndicator} from "../indicators";
import TakeOverStatus from "../take-over/TakeOverStatus";

const Inspection = () => {
        const {inspection, inspectionStatus, fetchInspection, inspectionError, inspectionWizardStep, inspectionWizardConfiguration, dealerKey} = useContext(DataContext);
        const {key} = useParams();
        const [completed, setCompleted] = useState(false);

        useEffect(() => {
            window.scrollTo(0, 0);
        }, []);

        useEffect(() => {
            const handleInspectionFetch = () => {
                if (inspection.key === undefined) {
                    fetchInspection(key, dealerKey);
                }
            };

            handleInspectionFetch();
        }, [key]);

        if (inspection && inspectionError) {
            return <Redirect to={`/${dealerKey}/no-inspection-found`}/>
        }

        if (inspection && !inspection.key) {
            return <div className="w-screen h-screen flex flex-1 items-center justify-center"><LoadingIndicator/></div>
        }

        if (inspection && !inspection.userId) {
            return (
                <Redirect
                    to={{
                        pathname: `/${dealerKey}/valuation/${inspection.key}`,
                    }}
                />
            )
        }


        const getWizardComponent = (step) => {
            if ((step > -1) && (step <= (inspectionWizardConfiguration.length - 1))) {
                return (
                    <div className="flex flex-1 justify-center">
                        <InspectionWizard/>
                    </div>
                );
            } else if (step === (inspectionWizardConfiguration.length)) {
                return (
                    <div className="flex flex-1 justify-center">
                        <InspectionContact onCompleted={() => {
                            setCompleted(true);
                        }}/>
                    </div>
                )
            } else {
                return (
                    <InspectionStart/>
                )
            }
        };

        if (completed || (inspectionStatus === 'COMPLETED' || inspectionStatus === 'SYNCED')) {
            return (
                <div className="flex flex-1 justify-center">
                    <TakeOverStatus redirect={completed} />
                </div>
            )
        } else {
            return (
                <React.Fragment>
                    {getWizardComponent(inspectionWizardStep)}
                </React.Fragment>
            );
        }


    }
;

export default Inspection;
