import React, {useContext} from 'react';
import {useDropzone} from "react-dropzone";
import {head} from "lodash";
import {ClientContext, useMutation} from "graphql-hooks";
import DataContext from "../../../data/context";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {v4} from "uuid";

export const DocumentUploadMutation =
    `mutation DocumentUpload($file: Upload!, $key: String!) { 
        documentUpload(file: $file, key: $key) { 
            ok
            url
        }
    }`;

const DropContainer = ({children, onUploadCompleted}) => {
        const {inspection, takeOverDocumentInitializeHandler, takeOverDocumentUpdateHandler} = useContext(DataContext);
        const [documentUploadMutation] = useMutation(DocumentUploadMutation);
        const {executeRecaptcha} = useGoogleReCaptcha();
        const client = useContext(ClientContext);

        const {getRootProps, getInputProps} = useDropzone({
            multiple: false,
            accept: 'image/jpeg, image/png, image/jpg, application/pdf',
            onDrop: acceptedFiles => {
                const fileToUpload = head(acceptedFiles);
                handleDocumentUpload(fileToUpload);

            }
        });

        const handleDocumentUpload = async (file) => {
            if (!executeRecaptcha) {
                return;
            }

            const token = await executeRecaptcha("valuation");

            client.setHeader('Captcha', token);

            const clientDocumentKey = v4();
            const parts = (file.name) ? file.name.split(".") : []
            const name = (parts[0] ? parts[0] : "");
            const extension = (parts[1] ? parts[1] : "");
            takeOverDocumentInitializeHandler(clientDocumentKey, name, extension);

            documentUploadMutation({variables: {file: file, key: inspection.key}})
                .then(res => {
                    const {ok, url} = res.data.documentUpload;

                    if (ok && url) {
                        takeOverDocumentUpdateHandler(clientDocumentKey, url);
                    }

                    if(onUploadCompleted)
                        onUploadCompleted()
                })
                .catch(error => {
                    console.log(error);
                    if(onUploadCompleted)
                        onUploadCompleted()
                });
        };

        return (
            <div className="flex flex-1 cursor-pointer overflow-hidden">
                <input {...getInputProps()} />
                <div {...getRootProps({className: 'dropzone'})} className="flex flex-1 outline-none focus:outline-none">
                    {children}
                </div>
            </div>
        );
    }
;

export default DropContainer;
