import React, {useContext} from 'react';
import StatusTable from "./StatusTable";
import DataContext from "../../../data/context";
import {useTranslation} from "react-i18next";

const StatusGeneral = ({title, statusTableVisible}) => {
    const {t} = useTranslation();
    const {takeOver} = useContext(DataContext);

    return (
        <div className="md:w-3/4">
            <div className="text-color_one text-24 font-bold">{title}</div>

            {takeOver.expertId &&
            <div className="text-12 mt-2">{t('reference')}: {takeOver.expertId}</div>}

            {statusTableVisible && <StatusTable/>}
        </div>
    );
};

StatusGeneral.defaultProps = {
    statusTableVisible: true,
};

export default StatusGeneral;
