import React, {useContext, useEffect, useState} from 'react';
import {hasValue, isValidEmail} from "../../validators";
import TextField from "../input/TextField";
import {useTranslation} from "react-i18next";
import {useMutation} from "graphql-hooks";
import DataContext from "../../data/context";
import {getUrlParams} from "../../utils";
import LoadingIndicator from "../indicators/LoadingIndicator";
import {useHistory, Redirect} from "react-router-dom";


const ACCOUNT_INITIALIZE_MUTATION = `
    mutation AccountInitialize($data: AccountInitializeInputType!) {
        accountInitialize(data: $data) {
           ok
        }
    }`;

export const ACCOUNT_CONFIRMATION_MUTATION = `
    mutation AccountConfirmation($data: AccountConfirmationInputType!) {
        accountConfirmation(data: $data) {
           ok
           token
           user {
             firstName
             lastName
           }
        }
    }`;

const Register = ({onRegisterPending}) => {
        const confirmation = getUrlParams(window.location.search)['confirmation'];
        const {dealerKey, dealerConfig, setAuthenticated} = useContext(DataContext);
        const [firstName, setFirstName] = useState("");
        const [lastName, setLastName] = useState("");
        const [email, setEmail] = useState("");
        const [errors, setErrors] = useState({});
        const [registrationPending, setRegistrationPending] = useState(false);
        const [accountInitializeMutation] = useMutation(ACCOUNT_INITIALIZE_MUTATION);
        const [accountConformMutation] = useMutation(ACCOUNT_CONFIRMATION_MUTATION);
        const {t} = useTranslation();
        let history = useHistory();

        useEffect(() => {

            if (confirmation) {
                const parts = confirmation.split('+');
                const key = (parts && parts[0]) ? parts[0] : "";
                const email = (parts && parts[1]) ? parts[1] : "";

                accountConformMutation({
                    variables: {
                        data: {
                            email: email,
                            key: key,
                            dealer: dealerKey
                        }
                    }
                }).then((result) => {
                    const {accountConfirmation} = result.data;
                    if (result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
                        const error = result.error.graphQLErrors[0].message;
                        let tmpErrors = {...errors};
                        tmpErrors['registration'] = error;
                        setErrors(tmpErrors)
                    } else {
                        if (accountConfirmation.ok) {
                            setAuthenticated(accountConfirmation.user);
                            history.push(`/${dealerKey}/account`)

                        } else {
                            let tmpErrors = {...errors};
                            tmpErrors['registration'] = 'Something went wrong'
                            setErrors(tmpErrors);
                        }
                    }
                })
            }
        }, [confirmation]);

        const handleSubmit = e => {
            e.preventDefault();

            if (validate()) {

                accountInitializeMutation({
                    variables: {
                        data: {
                            email: email,
                            firstName: firstName,
                            lastName: lastName,
                            dealer: dealerKey,
                        }
                    }
                }).then((result) => {
                        if (result.data && result.data.accountInitialize) {
                            const {accountInitialize} = result.data;
                            if (accountInitialize.ok) {
                                handleRegistrationComplete()
                            }
                        } else if (result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
                            const error = result.error.graphQLErrors[0].message;
                            let tmpErrors = {...errors};
                            tmpErrors['registration'] = error;
                            setErrors(tmpErrors)
                        } else {
                            let tmpErrors = {...errors};
                            tmpErrors['registration'] = 'Something went wrong';
                            setErrors(tmpErrors);
                        }
                    }
                )
            }
        };

        const validate = () => {
            let errors = {};

            if (!hasValue(firstName)) {
                errors['firstName'] = "Please fill out your first name";
            }

            if (!hasValue(lastName)) {
                errors['lastName'] = "Please fill out your last name";
            }

            if (!hasValue(email)) {
                errors['email'] = "Please enter your email address";
            } else if (!isValidEmail(email)) {
                errors['email'] = "Invalid email address";
            }

            setErrors(errors);

            return Object.keys(errors).length <= 0;
        };

        const handleRegistrationComplete = () => {
            setRegistrationPending(true);

            if (onRegisterPending) {
                onRegisterPending()
            }
        };

        if (confirmation) {
            return <LoadingIndicator/>
        }

        if (registrationPending) {
            return (
                <div>
                    <div className="mb-4">
                        <div className="text-lg">{t('Uw aanvraag is goed verzonden.')}</div>
                        <div>{t('Controleer uw mailbox voor verdere instructies.')}</div>
                    </div>
                </div>
            )
        }

        if (!dealerConfig.account) {
            return <Redirect to={`/${dealerKey}`}/>
        }

        return (
            <div className="" style={{minWidth: 300}}>
                <div className="mb-4">
                    <TextField label={t('Voornaam')}
                               placeholder={t('Voornaam')}
                               value={firstName}
                               onChange={value => {
                                   if (value) {
                                       // setErrors(omit(errors, ['firstName']));
                                       const {firstName, ...result} = errors;
                                       setErrors(result);
                                   }
                                   setFirstName(value)
                               }}
                               error={errors['firstName']}
                    />
                </div>

                <div className="mb-4">
                    <TextField label={t('Naam')}
                               placeholder={t('Naam')}
                               value={lastName}
                               onChange={value => {
                                   if (value) {
                                       // setErrors(omit(errors, ['lastName']));
                                       const {lastName, ...result} = errors;
                                       setErrors(result);
                                   }
                                   setLastName(value)
                               }}
                               error={errors['lastName']}
                    />
                </div>

                <div className="mb-4">
                    <TextField label={t('Email')}
                               placeholder={t('Email')}
                               type="email"
                               value={email}
                               onChange={value => {
                                   if (value) {
                                       const {email, ...result} = errors;
                                       setErrors(result);
                                   }
                                   setEmail(value)
                               }}
                               error={errors['email']}
                    />
                </div>

                {Object.keys(errors).length > 0 && errors['registration'] !== "" &&
                <div className="bg-red-200 text-red-800 p-4 w-full mb-4">
                    {errors['registration']}
                </div>}

                <div className="mb-4">
                    <div className="flex rounded-full uppercase bg-white border-2 border-black text-center items-center justify-center font-bold text-12 cursor-pointer tracking-widest md:w-1/2"
                         style={{minHeight: 40}}
                         onClick={handleSubmit}>
                        {t('Account aanmaken')}
                    </div>
                </div>

                <div className="mt-6">
                    <div>{t("Heb je al een account?")} <span className="underline cursor-pointer mr-1" onClick={() => history.push(`/${dealerKey}/login`)}>{t('Inloggen')}</span>{t('dan maar.')}</div>
                </div>

            </div>


        );
    }
;

export default Register;
