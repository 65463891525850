import React from 'react';

const SelectBox = ({description, selected, onChange, error=null}) => {
    return (
        <div onClick={() => onChange(!selected)}>
            <div className={`flex bg-white rounded items-center justify-center border cursor-pointer ${error ? "border-red-500" : "border-gray-700"}`} style={{width: 20, height: 20}}>
                {selected &&
                <i className="fal fa-check text-14 font-bold text-color_three cursor-pointer" />}
            </div>

            {description && description !== "" &&
            <div className={`${selected ? 'text-color_one' : 'text-color_three'} pl-4 font-bold uppercase text-14`}>
                {description}
            </div>}
        </div>
    );
};

export default SelectBox;
