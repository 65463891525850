import {useState, useEffect} from 'react';
import moment from 'moment';
import {useTranslation} from "react-i18next";

const makesQuery = `query Makes {
  makes{
     id
     key
     name       
     priority 
     disabled
  }
}`;

const modelsQuery = `query Models($make: String!, $age: Int!) {
  models(make: $make, age: $age) {
     id
     key
     name    
     disabled    
  }
}`;

const bodiesQuery = `query Bodies($model: String!) {
  bodyTypes(model: $model){
     id
     key
     icon
     description
  }
}`;

const fuelsQuery = `query Fuels($model: String!) {
  fuels(model: $model){
     id
     key
     description
  }
}`;

const transmissionsQuery = `query Transmissions($model: String!) {
  transmissions(model: $model){
     id
     key
     description
  }
}`;

const engineRangesQuery = `query EngineRanges($model: String!, $fuel: String!) {
  engineRanges(model: $model, fuel: $fuel){
     id
     key
     description
     kw
     priority
  }
}`;

const powerRangesQuery = `query powerRanges($model: String!) {
    powerRanges(model: $model){
       id
       key
       description
       kw
       priority
    }
  }`;

export function useData(inspection, status) {
    const {i18n} = useTranslation();
    const [loading] = useState(true);
    const [error] = useState(null);
    const [makes, setMakes] = useState([]);
    const [models, setModels] = useState([]);
    const [bodies, setBodies] = useState([]);
    const [fuels, setFuels] = useState([]);
    const [engineRanges, setEngineRanges] = useState([]);
    const [powers, setPowers] = useState([]);
    const [transmissions, setTransmissions] = useState([]);

    useEffect(() => {

        fetchMakes();
    }, [status, inspection.key]);

    useEffect(() => {
        if (inspection.make && inspection.firstRegistrationMonth) {
            setModels([]);
            const age = moment().diff(moment([inspection.firstRegistrationYear, inspection.firstRegistrationMonth - 1, 1]), 'months');
            fetchModels(inspection.make, age)
        }
    }, [inspection.make, inspection.firstRegistrationMonth]);

    useEffect(() => {
        if (inspection.model) {

            setBodies([]);
            setFuels([]);
            setTransmissions([]);
            fetchBodies(inspection.model);
            fetchFuels(inspection.model);
            console.log('pre fecth power')
            fetchPowers(inspection.model)
            fetchTransmissions(inspection.model)
        }

        if (inspection.model && inspection.fuel) {
            fetchEnginesRanges(inspection.model, inspection.fuel);

        }
    }, [inspection.model, inspection.fuel, i18n.language]);

    const fetchMakes = () => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': i18n.language},
                body: JSON.stringify({query: makesQuery})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data.makes) {
                    setMakes(res.data.makes);
                }
            })
            .catch(error => {
            });
    };

    const fetchModels = (make, age) => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': i18n.language},
                body: JSON.stringify({query: modelsQuery, variables: {make: make, age: age}})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data.models) {
                    setModels(res.data.models);
                }
            })
            .catch(error => {
            });
    };

    const fetchBodies = (model) => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': i18n.language},
                body: JSON.stringify({query: bodiesQuery, variables: {model: model}})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data.bodyTypes) {
                    setBodies(res.data.bodyTypes);
                }
            })
            .catch(error => {
            });
    };

    const fetchFuels = (model) => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': i18n.language},
                body: JSON.stringify({query: fuelsQuery, variables: {model: model}})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data.fuels) {
                    setFuels(res.data.fuels);
                }
            })
            .catch(error => {
            });
    };

    const fetchTransmissions = (model) => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': i18n.language},
                body: JSON.stringify({query: transmissionsQuery, variables: {model: model}})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data.transmissions) {
                    setTransmissions(res.data.transmissions);
                }
            })
            .catch(error => {
            });
    };

    const fetchEnginesRanges = (model, fuel) => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': i18n.language},
                body: JSON.stringify({query: engineRangesQuery, variables: {model: model, fuel: fuel}})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data.engineRanges) {
                    setEngineRanges(res.data.engineRanges);
                }
            })
            .catch(error => {
            });
    };

    const fetchPowers = (model, fuel) => {
        fetch(
            '/graphql', {
                method: 'POST',
                headers: {'Content-Type': 'application/json', 'Accept-Language': i18n.language},
                body: JSON.stringify({query: powerRangesQuery, variables: {model: model}})
            })
            .then(res => res.json())
            .then(res => {
                if (res.data.powerRanges) {
                    setPowers(res.data.powerRanges);
                }
            })
            .catch(error => {
                console.log(error)
            });
    };

    return {
        makes,
        models,
        bodies,
        fuels,
        engineRanges: engineRanges,
        powers,
        transmissions,
        loading: loading,
        error
    };
}
