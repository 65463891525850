import React, {useContext, useEffect} from 'react';
import Content from "./components/Content";
import {parseUrl} from "../../utils";
import DataContext from "../../data/context";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";

const TermsAndConditions = () => {
    const {dealer, dealerConfig} = useContext(DataContext);
    const { i18n } = useTranslation();
    let history = useHistory();

    useEffect(() => {
        if(dealerConfig.termsAndConditionsUrl && dealerConfig.termsAndConditionsUrl !== "") {
            window.open(parseUrl(dealerConfig.termsAndConditionsUrl, i18n.language))
            history.goBack()
        }
    }, []);

    return (
        <div className="flex flex-1 md:justify-center md:mb-12 md:mt-12">
            <div className="flex flex-1 md:justify-center p-4 md:p-0" style={{maxWidth: 1024}}>
                <Content type="terms_and_conditions"/>
            </div>
        </div>
    );
};

export default TermsAndConditions;
