import React, { useContext, useEffect, useState } from "react";
import DataContext from "../../../data/context";
import { useTranslation } from "react-i18next";
import { keys, map, orderBy } from "lodash";
import WizardButtons from "../WizardButtons";
import WizardError from "../WizardError";
import TagManager from "react-gtm-module";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS } from "../../../tagManager";

const BodyType = () => {
  const { inspection, data, setField, setNextVehicleDataStep } = useContext(
    DataContext
  );
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    if (errors && keys(errors).length > 0) {
      validate();
    }
  }, [inspection]);

  useEffect(() => {
    let aTrack = analyticstracker();
    aTrack.trackImpression("tool-start");
  }, []);

  const tagArgsStart = TAGMANAGER_TOOL_ARGS({
    event: "start",
    toolStep: "body",
    toolStepNumber: "4",
  });

  const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

  const validate = () => {
    let errors = {};

    if (!inspection.body) {
      errors["body"] = t("Please select a body type");
      let aTrack = analyticstracker();
      let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
          event: "error",
          toolStep: 'body',
          toolStepNumber: "4",
          errorType: 'user-error',
          errorMessage: errors.body
      })
      let event = { "event": "tool-error", "info": JSON.parse(errorTrackingInfo), "commerce": JSON.parse(tagArgsCommerce)};
      aTrack.trackEvent(event);
    }

    setErrors(errors);

    return keys(errors).length <= 0;
  };

  const validator = () => {
    return validate();
  };

  const handleBodyClick = (e, body) => {
    setField("body", body.key);
    let aTrack = analyticstracker();
    aTrack.trackInteraction(e)
    setNextVehicleDataStep();
  };

  const bodyOptions =
    data && data.bodies
      ? orderBy(data.bodies, ["priority", "name"], ["desc", "asc"])
      : [];

  return (
    <div data-tracking-event="tool-start" data-tracking-info={tagArgsStart} data-tracking-commerce={tagArgsCommerce}>
      <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
        {t("Body type")}
      </div>

      <div className="text-16 leading-6 mb-4 mt-6">
        {t("Select the body type of your vehicle")}:
      </div>

      <div className="flex flex-wrap mb-10">
        {map(bodyOptions, (body, i) => {
          const selected = body.key === inspection.body;
          const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
            event: 'submit',
            toolStep: "body",
            toolStepNumber: "4",
            toolStepOption: body.description,
          })
          return (
            <div
              key={`body_${body.key}`}
              className={`w-1/3 mb-2 ${i % 3 !== 2 ? "pr-2" : ""}`}
            >
              <div
                className={`flex flex-col items-center justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${
                  selected
                    ? "border-color_one text-color_one"
                    : "border-color_five text-color_three"
                } hover:border-color_one hover:text-color_one`}
                style={{ height: 70 }}
                data-tracking-event="tool-submit"
                data-tracking-info={tagArgsSubmit}
                data-tracking-commerce={tagArgsCommerce}
                onClick={(e) => handleBodyClick(e, body)}
              >
                <i
                  className={`${body.icon} ${selected ? "text-color_one" : ""}`}
                  style={{ fontSize: 25 }}
                />
                <span className="mt-2 font-bold text-12">
                  {body.description}
                </span>
              </div>
            </div>
          );
        })}
      </div>

      <WizardError errors={errors} />

      <WizardButtons validatorFunction={validator} />
    </div>
  );
};

export default BodyType;
