import React, {useContext, useEffect, useState} from 'react';
import {formatPrice} from "../../utils";
import {StickyElements} from "../../data/constants";
import {useTranslation} from "react-i18next";
import {useValuation} from "../../hooks/useValuation";
import DataContext from "../../data/context";
import {LoadingIndicator} from "../indicators";

const minHeight = 40;

const CurrentValuation = () => {
    const [topSpace, setTopSpace] = useState(0);
    const {inspection, dealerConfig} = useContext(DataContext);
    const {valuation, loading, hasValuation} = useValuation(inspection);

    const {t} = useTranslation();

    useEffect(() => {
        const headerSelector = document.getElementsByClassName(StickyElements.header);
        if (headerSelector.length > 0) {
            const [head] = headerSelector;
            const headerHeight = head.offsetHeight;
            setTopSpace(headerHeight);
        }
    }, []);

    if (!dealerConfig.valuation) {
        return null
    }

    return (
        <div className={`${StickyElements.currentPrice} flex w-full bg-color_one text-white items-center justify-center font-bold sticky z-20`} style={{minHeight: minHeight, top: 0}}>

            {hasValuation &&
            <React.Fragment>
                {t('Current price estimate')}: <span className="ml-2">{loading ? <LoadingIndicator extraSmall color="white"/> : formatPrice(valuation.guide)}</span>
            </React.Fragment>}

            {!hasValuation &&
            <span>
                {t('We were not able to calculate a price. Complete the inspection and we\'ll get started!')}
            </span>}

        </div>
    );
};

export default CurrentValuation;
