import React, {useContext, useEffect, useState} from 'react';
import {getWizardStepsByDealerConfig, wizardSteps} from "./vehicleWizardConfig";
import DataContext from "../../data/context";
import StepIndicator from "./StepIndicator";
import {useHeight} from "../../hooks/useHeight";
import {StickyElements} from "../../data/constants";
import VehicleSummary from "./VehicleSummary";


const VehicleWizard = () => {
    const {vehicleWizardStep, dealerConfig} = useContext(DataContext);
    const [summaryVisible, setSummaryVisible] = useState(false);
    const topSpace = useHeight([StickyElements.header]);
    const wizardSteps = getWizardStepsByDealerConfig(dealerConfig)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [vehicleWizardStep]);

    const stepData = wizardSteps[vehicleWizardStep] ? wizardSteps[vehicleWizardStep] : null;

    if (!stepData) {
        return null;
    }

    return (
        <div className="w-full relative">
            <div className="sticky z-10 md:hidden" style={{top: topSpace}}>
                <StepIndicator onInfoClicked={() => {
                    setSummaryVisible(true)
                }}/>
            </div>

            <div className="w-full md:flex md:justify-center">
                <div className="md:flex md:w-full md:px-8 lg:px-0 md:justif@y-center" style={{maxWidth: 1024}}>

                    <div className="hidden md:block bg-white md:w-3/5 md:my-20">
                        <div className={`vehicle-summary-container flex flex-col bg-color_one text-white md:rounded-lg relative md:pt-10 sticky`} style={{top: (topSpace && topSpace > 0) ? `calc(${topSpace}px + 2rem)` : 0}}>
                            {/*<StepIndicator/>*/}
                            <VehicleSummary />
                        </div>
                    </div>


                    <div className="md:w-3/5 mt-5 lg:mt-10 ml-10 mr-10">
                        {stepData.component}
                    </div>
                </div>
            </div>

            {summaryVisible &&
            <div className="vehicle-summary-container absolute top-0 left-0 right-0 bottom-0 w-full h-screen z-50 bg-white text-color_one">
                <VehicleSummary onClose={() => setSummaryVisible(false)}/>
            </div>}
        </div>

    )

};

export default VehicleWizard;
