import React, {useContext, useEffect} from 'react';
import analyticstracker from "analyticstracker";

import DataContext from "../../../data/context";
import {useTranslation} from "react-i18next";
import StatusGeneral from "../components/StatusGeneral";
import {formatPrice} from "../../../utils";
import { TAGMANAGER_PAGE_ARGS } from "../../../tagManager";

const Accepted = () => {
    const {inspection, takeOver, dealerConfig} = useContext(DataContext);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        analyticstracker().trackImpression("page-impression");
      }, []);

    const tagInfo = TAGMANAGER_PAGE_ARGS({
        pageName: "car-quotation/valuation-2",
        section: "valuation-2-proposal-confirmed",
        language: i18n.language,
    });

    return (
        <React.Fragment>
            <StatusGeneral title={`${t("All done")}!`}/>

            <div className="md:w-3/4"
                data-tracking-event="page-impression"
                data-tracking-info={tagInfo}>

                <div className="flex flex-col items-center justify-center bg-color_success rounded-lg p-4">
                    <div className="flex w-full">
                        <div className="flex flex-col  items-center justify-center mr-6 w-1/2">
                            <div className="text-20 font-bold text-color_one mb-2">{formatPrice(takeOver.acceptedAmount)}</div>

                            {!dealerConfig.transport &&
                            <div className="text-color_three text-12">{t("Incl VAT")}</div>}

                            {dealerConfig.transport &&
                            <div className="text-color_three text-12">{t("Incl VAT and pickup")}</div>}

                        </div>
                        <div className="flex items-center justify-center w-1/2">
                            <div className="text-color_three ">
                                <div className="mb-1">{inspection.firstName} {inspection.lastName}</div>
                                <div className="mb-1">{inspection.street} {inspection.number}</div>
                                <div className="mb-1">{inspection.postalCode} {inspection.city}</div>
                                <div className="mb-1">T: {inspection.telephone}</div>
                                <div className="mb-1">E: {inspection.email}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mb-4 mt-4 text-color_three leading-6">{t("We agree on a time when you can deliver the car")}</div>
                <p className="text-color_three mt-4 leading-6  mb-4">{t("We'll do a final inspection when the car arrives. After that we're all done and ready to compleet the deal! We'll immediately transfer the money to your personal account.")}</p>

                <p className="text-color_one font-bold text-16">{t('Questions?')}</p>
                <div className="text-color_three mt-4 mb-6">{t("Feel free to contact us or visit us in store.")}</div>

            </div>
        </React.Fragment>
    )
};

export default Accepted;
