import React, {useContext} from 'react';
import {getDealerFromUrl} from "../../utils";
import DataContext from "../../data/context";
import {useHistory} from "react-router-dom";

const AuthButton = () => {
    const {isAuthenticated, signOut, session, dealerKey} = useContext(DataContext);
    let history = useHistory();

    if (isAuthenticated()) {
        return (
            <div className="flex">
                <div className="cursor-pointer" onClick={() => {
                    history.push(`/${dealerKey}/account`)
                }}>{(session.user.firstName) ? `${session.user.firstName} ${session.user.lastName}`  : session.user.email} -</div>
                <div className="cursor-pointer" onClick={() => {
                    signOut()
                }}>Sign Out
                </div>
            </div>
        )
    }

    return (
        <div className="" onClick={() => {
            history.push(`/${getDealerFromUrl(window.location.pathname)}/login`)
        }}>
            Account
        </div>
    );
};

export default AuthButton;
