import React, {useContext, useState} from 'react';
import {getDealerFromUrl} from "../../utils";
import {hasValue, isValidEmail} from "../../validators";
import TextField from "../input/TextField";
import {useTranslation} from "react-i18next";
import {Redirect, useHistory} from "react-router-dom";
import DataContext from "../../data/context";
import {useMutation} from "graphql-hooks";
import {Facebook, Google, Microsoft, LinkedIn} from "../social";
import {getSocialAuthUrlForProvider} from "../social/utils";

export const AUTH_MUTATION = `
    mutation Auth($username: String!, $password: String!, $dealer: String!) {
        auth(username: $username, password: $password, dealer: $dealer) {          
           user {
              firstName
              lastName
              email
            }
        }
    }`;

const Login = () => {
    const {dealerKey, dealerConfig, isAuthenticated, setAuthenticated} = useContext(DataContext);
    const [authMutation] = useMutation(AUTH_MUTATION);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const {t} = useTranslation();
    let history = useHistory();

    const handleAuthenticate = (email, password) => {
        authMutation({
            variables: {
                username: email,
                password: password,
                dealer: dealerKey,
            }
        }).then((result) => {
            if (result.data && result.data.auth) {
                const {auth} = result.data;
                if (auth.user) {
                    setAuthenticated(auth.user);
                    handleOnAuthenticated(auth.user)
                }
            } else if (result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
                const error = result.error.graphQLErrors[0];
                if (error.code === '401') {
                    let tmpErrors = {...errors};
                    tmpErrors['auth'] = t("Ongeldig email adress of wachtwoord");
                    setErrors(tmpErrors)
                } else {
                    let tmpErrors = {...errors};
                    tmpErrors['auth'] = error.message;
                    setErrors(tmpErrors)
                }
            } else {
                let tmpErrors = {...errors};
                tmpErrors['auth'] = 'Something went wrong';
                setErrors(tmpErrors);
            }
        })
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {
            handleAuthenticate(email, password)
        }
    };

    const validate = () => {
        let errors = {};

        if (!hasValue(email)) {
            errors['email'] = "Ongeldig e-mailadres";
        } else if (!isValidEmail(email)) {
            errors['email'] = "Ongeldig e-mailadres";
        }

        if (!hasValue(password)) {
            errors['password'] = "Ongeldig wachtwoord";
        }

        setErrors(errors);

        return Object.keys(errors).length <= 0;
    };

    const handleOnAuthenticated = () => {
        history.push(`/${getDealerFromUrl(window.location.pathname)}/account`)
    };

    if (!dealerConfig.account) {
        return <Redirect to={`/${dealerKey}`}/>
    }

    if (isAuthenticated()) {
        return <Redirect to={`/${dealerKey}/account`}/>
    }

    const handleSocialAuth = (provider) => {
        window.location.href = getSocialAuthUrlForProvider(provider, dealerKey, `${window.location.origin}/${dealerKey}/login`);
    };

    return (
        <div>
            <div className="flex flex-1 justify-center  ">
                <div className="mr-2">
                    <Google onClick={() => handleSocialAuth('google-oauth2')}/>
                </div>
                <div className="mr-2">
                    <Facebook onClick={() => handleSocialAuth('facebook')}/>
                </div>
                <div className="mr-2">
                    <LinkedIn onClick={() => handleSocialAuth('linkedin-oauth2')}/>
                </div>
                <div className="">
                    <Microsoft onClick={() => handleSocialAuth('microsoft-graph')}/>
                </div>
            </div>

            <div className="w-full border-b border-color_five text-center mb-5 mt-5" style={{height: 10}}>
                          <span className="text-10 pl-4 pr-4 bg-white" style={{color: '#B9BED1'}}>
                              {t('or')}
                          </span>
            </div>


            <div className="mb-5">
                <div className="mb-4">
                    <TextField label={t('E-mail')}
                               value={email}
                               onChange={(value) => setEmail(value.toLowerCase())}
                               error={errors['email']}
                    />
                </div>
                <div className="mb-4">
                    <TextField label={t('Wachtwoord')}
                               type='password'
                               value={password}
                               onChange={(value) => setPassword(value)}
                               error={errors['password']}
                    />
                </div>
                <div className="mb-4">
                    <div className="flex rounded-full uppercase bg-white border-2 border-black text-center items-center justify-center font-bold text-12 cursor-pointer tracking-widest md:w-1/2"
                         style={{minHeight: 40}}
                         onClick={handleSubmit}>
                        {t('Aanmelden')}
                    </div>
                </div>

                <div className="mt-6">
                    <div className="underline mb-2 cursor-pointer" onClick={() => history.push(`/${dealerKey}/forgot-password`)}>{t('Wachtwoord vergeten?')}</div>
                    <div className="underline cursor-pointer" onClick={() => history.push(`/${dealerKey}/register`)}>{t("Nog geen account?")} </div>
                </div>
            </div>


            {Object.keys(errors).length > 0 && errors['auth'] !== "" &&
            <div className="bg-red-200 text-red-800 p-4 w-full mb-4">
                {errors['auth']}
            </div>}
        </div>
    );
};

export default Login;
