import React, {useContext, useEffect, useState} from 'react';
import DataContext from "../../../data/context";
import {useTranslation} from "react-i18next";
import {keys, map, orderBy} from "lodash";
import WizardButtons from "../WizardButtons";
import WizardError from "../WizardError";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS } from "../../../tagManager";

const Engine = () => {
    const {inspection, data, setField, setNextVehicleDataStep} = useContext(DataContext);
    const [showHelp, setShowHelp] = useState(false);
    const [errors, setErrors] = useState({});
    const {t} = useTranslation();

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection]);

    useEffect(() => {
        let aTrack = analyticstracker();
        aTrack.trackImpression('tool-start');
      }, []);

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: "start",
        toolStep: "engine",
        toolStepNumber: "6",
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {};

        if (!inspection.engine) {
            errors['engine'] = t("Please select engine");
            let aTrack = analyticstracker();
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: "error",
                toolStep: 'engine',
                toolStepNumber: "6",
                errorType: 'user-error',
                errorMessage: errors.engine
            })
            let event = { "event": "tool-error", "info": JSON.parse(errorTrackingInfo), "commerce": JSON.parse(tagArgsCommerce) };
            aTrack.trackEvent(event);
        }

        setErrors(errors);

        return keys(errors).length <= 0;
    };

    const validator = () => {
        return validate();
    };

    const handleEngineClick = (e, engine) => {
        setField('engineRange', engine.key);
        setField('kw', engine.kw);

        let aTrack = analyticstracker();
        aTrack.trackInteraction(e)

        setNextVehicleDataStep();
    };

    const handlePowerClick = (e, power) => {
        setField('engineRange', '');
        setField('powerRange', power.key)
        setField('kw', power.kw);

        let aTrack = analyticstracker();
        aTrack.trackInteraction(e)

        setNextVehicleDataStep();
    };

    const engineRangeOptions = (data && data.engineRanges) ? orderBy(data.engineRanges, ['priority'], ['asc']) : [];
    const enginePowerRangeOptions = (data && data.powers) ? orderBy(data.powers, ['priority'], ['asc']) : [];

    const handleShowHelp = () => {
        if(!showHelp) {
            setShowHelp(true)
        }
    };

    const handleHideHelp = () => {
        setTimeout(() => {
            setShowHelp(false)
        }, 250);
    };

    const renderCC = () => (
        <>
        <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('EngineCC')}</div>

        <div className="flex">
            <div className="text-16 leading-6 mb-4 mt-6">{t('Select the engine of your vehicle')}:</div>
            <div className="flex items-center mb-4 mt-6 ml-1 relative">
                <div onMouseEnter={handleShowHelp}
                     onMouseLeave={handleHideHelp}
                     onClick={() => setShowHelp(!showHelp)}>
                    <i className="fal fa-question-circle ml-1 text-20 text-gray-700 hover:text-gray-900 cursor-pointer "/>

                    {showHelp &&
                    <div className="absolute right-0 top-0 border border-gray-400 shadow-2xl bg-white p-1 rounded z-50" style={{top: 25, width: 300}}>
                        <img src={require("../../../assets/help-images/engineCC.jpg")} alt="" style={{maxWidth: "100%"}} className="rounded"/>
                    </div>}
                </div>
            </div>
        </div>
        <div className="flex flex-wrap mb-10">
            {map(engineRangeOptions, (engine, i) => {
                const selected = engine.key === inspection.engine;
                const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                    event: 'submit',
                    toolStep: "engine",
                    toolStepNumber: "6",
                    toolStepOption: engine.description,
                  })
                return (
                    <div key={`engine_${engine.key}`} className={`w-1/2 mb-2 ${((i % 2) !== 1) ? 'pr-2' : ''}`}>
                        <div
                            className={`flex items-center justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${(selected) ? 'border-color_one text-color_one' : 'border-color_five text-color_three'} hover:border-color_one hover:text-color_one truncate`}
                            style={{height: 70}}
                            data-tracking-event="tool-submit"
                            data-tracking-info={tagArgsSubmit}
                            data-tracking-commerce={tagArgsCommerce}
                            onClick={(e) => handleEngineClick(e,engine)}>
                            {engine.description}
                        </div>
                    </div>
                )
            })}
        </div>
        </>
    )
    
    const renderKw = () => (
        <>
                <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('powerKw')}</div>
                <div className="flex">
                <div className="text-16 leading-6 mb-4 mt-6">{t('Select the enginepower of your vehicle')}:</div>
                <div className="flex items-center mb-4 mt-6 ml-1 relative">
                    <div onMouseEnter={handleShowHelp}
                        onMouseLeave={handleHideHelp}
                        onClick={() => setShowHelp(!showHelp)}>
                        <i className="fal fa-question-circle ml-1 text-20 text-gray-700 hover:text-gray-900 cursor-pointer "/>

                        {showHelp &&
                        <div className="absolute right-0 top-0 border border-gray-400 shadow-2xl bg-white p-1 rounded z-50" style={{top: 25, width: 300}}>
                            <img src={require("../../../assets/help-images/engineCC.jpg")} alt="" style={{maxWidth: "100%"}} className="rounded"/>
                        </div>}
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap mb-10">
                {map(enginePowerRangeOptions, (power, i) => {
                    const selected = power.key === inspection.engine;
                    const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                        event: 'submit',
                        toolStep: "engine",
                        toolStepNumber: "6",
                        toolStepOption: power.description,
                      })
                    return (
                        <div key={`power_${power.key}`} className={`w-1/2 mb-2 ${((i % 2) !== 1) ? 'pr-2' : ''}`}>
                            <div
                                className={`flex items-center justify-center bg-color_five border text-14 rounded-lg mb-2 cursor-pointer ${(selected) ? 'border-color_one text-color_one' : 'border-color_five text-color_three'} hover:border-color_one hover:text-color_one truncate`}
                                style={{height: 70}}
                                data-tracking-event="tool-submit"
                                data-tracking-info={tagArgsSubmit}
                                onClick={(e) => handlePowerClick(e,power)}>
                                {`${power.description} kW`} 
                            </div>
                        </div>
                    )
                })}
            </div>
        </>
    )
    return (
        <div data-tracking-event="tool-start" data-tracking-info={tagArgsStart} data-tracking-commerce={tagArgsCommerce}>
            {inspection.fuel === 'elektrisch' ? renderKw() : renderCC()}

            <WizardError errors={errors}/>

            <WizardButtons validatorFunction={validator}/>
        </div>
    );
};

export default Engine;
