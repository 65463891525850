import React, {useContext, useState} from 'react';
import {hasValue, isValidEmail} from "../../validators";
import TextField from "../input/TextField";
import {useTranslation} from "react-i18next";
import {useMutation} from "graphql-hooks";
import DataContext from "../../data/context";
import {Redirect} from "react-router-dom";

export const ACCOUNT_RESET_PASSWORD_REQUEST_MUTATION = `
    mutation AccountResetPasswordRequest($data: AccountResetPasswordRequestInputType!) {
        accountResetPasswordRequest(data: $data) {
           ok
        }
    }`;

const ForgotPassword = () => {
    const {dealerKey, dealerConfig} = useContext(DataContext);
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState({});
    const [pending, setPending] = useState(false);
    const [accountResetPasswordRequestMutation] = useMutation(ACCOUNT_RESET_PASSWORD_REQUEST_MUTATION);
    const {t} = useTranslation();


    const handleSubmit = (e) => {
        e.preventDefault();

        if (validate()) {

            accountResetPasswordRequestMutation({
                variables: {
                    data: {
                        email: email,
                        dealer: dealerKey
                    }
                }
            }).then((result) => {
                if (result.data && result.data.accountResetPasswordRequest) {
                    const {accountResetPasswordRequest} = result.data;
                    if (accountResetPasswordRequest.ok) {
                        setPending(true);
                    }
                } else if (result.error && result.error.graphQLErrors && result.error.graphQLErrors.length > 0) {
                    const error = result.error.graphQLErrors[0].message;
                    let tmpErrors = {...errors};
                    tmpErrors['forgotpw'] = error;
                    setErrors(tmpErrors)
                } else {
                    let tmpErrors = {...errors};
                    tmpErrors['forgotpw'] = 'Something went wrong';
                    setErrors(tmpErrors);
                }

            });
        }
    };

    const validate = () => {
        let errors = {};

        if (!hasValue(email)) {
            errors['email'] = "Ongeldig e-mailadres";
        } else if (!isValidEmail(email)) {
            errors['email'] = "Ongeldig e-mailadres";
        }

        setErrors(errors);

        return Object.keys(errors).length <= 0;
    };

    if (!dealerConfig.account) {
        return <Redirect to={`/${dealerKey}`}/>
    }


    if (pending) {
        return (
            <div>
                <div className="mb-4">
                    <div className="text-lg">{t('Uw aanvraag is goed verzonden.')}</div>
                    <div>{t('Controleer uw mailbox voor verdere instructies.')}</div>
                </div>
            </div>
        )
    }

    return (
        <div className="" style={{minWidth: 300}}>
            <div className="mb-1 lg:mb-3 text-2xl md-text-4xl px-5 md:px-0">Wachtwoord instellen</div>
            <div className="mb-8 lg:mb-8 px-5 md:px-0">Stuur je e-mailadres en je ontvangt binnen enkele minuten een link waarmee je een wachtwoord kunt instellen.</div>

            <div className="mb-4">
                <TextField label={t('E-mail')}
                           value={email}
                           onChange={(value) => setEmail(value.toLowerCase())}
                           error={errors['email']}
                />
            </div>

            {Object.keys(errors).length > 0 && errors['forgotpw'] !== "" &&
            <div className="bg-red-200 text-red-800 p-4 w-full mb-4">
                {errors['forgotpw']}
            </div>}

            <div className="mb-4">
                <div className="mb-4">
                    <div
                        className="flex rounded-full uppercase bg-white border-2 border-black text-center items-center justify-center font-bold text-12 cursor-pointer tracking-widest md:w-1/2"
                        style={{minHeight: 40}}
                        onClick={handleSubmit}>
                        {t('Versturen')}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default ForgotPassword;
