import React from 'react';
import {useHistory} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {getDealerFromUrl} from "../utils";

const Page404 = ({showBack}) => {
    let history = useHistory();
    const { t } = useTranslation();

    return (
        <div className='flex flex-col items-center justify-center flex-1 w-screen py-24 md:py-56'>
            <div className="flex items-center justify-center text-color_one font-bold mb-3" style={{fontSize: isMobile ? 90 : 150}}>
                4 <i className="fas fa-car-crash mx-2 text-color_one" style={{fontSize: isMobile ? 70 : 130}} /> 4
            </div>
            <div className={`text-color_three ${isMobile ? "text-20 mb-5" : "text-24 mb-10"} text-center px-10 `}>
                {t("Oops, looks like our GPS lost the signal.")}
            </div>

            {showBack &&
            <div className="flex rounded-full items-center justify-center text-center font-bold cursor-pointer py-3 px-5 text-14 tracking-widest border-2 border-color_one text-color_one uppercase" onClick={() => history.push(`/${getDealerFromUrl(window.location.pathname)}`)}>
                <i className="fal fa-angle-left mr-2 text-16" /> {t('Back to home')}
            </div>}
        </div>
    );
};

Page404.defaultProps = {
    showBack: true,
};

export default Page404;
