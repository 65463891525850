import React, {useContext, useState, useEffect} from "react";
import WizardButtons from "../WizardButtons";
import {find, includes, keys, map} from "lodash";
import {useTranslation} from "react-i18next";
import {useManualQuery} from "graphql-hooks";
import DataContext from "../../../data/context";
import {LoadingIndicator} from "../../indicators";
import {geocodeByAddress} from "react-places-autocomplete";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS } from "../../../tagManager";

const dealershipsQuery = `query DealerQuery($search: DealershipSearchInputType!) {
  dealerships(search: $search) {
    id
    name
    distance
    street
    zip
    city
  }
}`;

async function resolveZipFromGoogle(input) {
    let result = undefined;

    await geocodeByAddress(input).then((r) => {
        if (r.length > 0) {
            const country = find(r[0].address_components, (item) => includes(item.types, 'country'));
            const zip = find(r[0].address_components, (item) => includes(item.types, 'postal_code'));
            if (country && country.short_name === "BE") {
                result = (zip) ? zip.short_name : undefined
            }
        }
    })
    return result;
}

const DealerLocator = ({lma, handlePrevious}) => {
        const {inspection, setField, dealerKey} = useContext(DataContext);
        const [postal, setPostal] = useState((inspection.postalCode) ? inspection.postalCode : undefined)

        const [fetchDealerships, {data, loading}] = useManualQuery(dealershipsQuery);
        const [errors, setErrors] = useState({});
        const {t} = useTranslation();

        useEffect(() => {
            if (postal !== undefined && postal.length === 4) {
                const make = (inspection.purchase && inspection.purchase.make) ? inspection.purchase.make : inspection.make
                fetchDealerships({
                    variables: {
                        search: {make: make, zip: postal, lma: lma, dealer: dealerKey},
                    }
                });
            }
        }, [postal]);


        async function handlePostalChange() {
            // const zipFromGoogle = await resolveZipFromGoogle(address)
            setPostal(postal)
        }

        const validate = () => {
            let errors = {};

            if (!inspection.dealer) {
                errors["dealer"] = t("Please select your a dealer");
                let aTrack = analyticstracker();
                let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                    event: "error",
                    toolStep: 'personal-info',
                    toolStepNumber: "11",
                    errorType: 'user-error',
                    errorMessage: errors.dealer
                })
                let commerceInfo = TAGMANAGER_COMMERCE_ARGS({})
                let event = { "event": "tool-error", "info": JSON.parse(errorTrackingInfo), "commerce": JSON.parse(commerceInfo) };
                aTrack.trackEvent(event);
            }

            setErrors(errors);

            return keys(errors).length <= 0;
        };

        const validator = () => {
            return validate();
        };

        const handleSelectDealer = (dealer) => {
            setField("dealer", dealer.id);
        };

        return (
            <div>
                <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">
                    {t("Find a dealer near you.")}
                </div>

                <React.Fragment>
                    <div className="mb-4">
                        <label className="block uppercase tracking-wide text-14 font-bold mb-1 text-color_one">
                            {t("Postal")}
                        </label>
                        <input
                            value={postal}
                            onChange={(e) => setPostal(e.target.value)}
                            onBlur={handlePostalChange}
                            className={`appearance-none block w-full bg-color_five border rounded-lg py-3 px-4 focus:outline-none focus:border-primary text-14`}
                            disabled={loading}
                        />
                    </div>

                    {loading &&
                    <div className="flex items-center justify-center bg-white relative" style={{height: 300}}>
                        <LoadingIndicator/>
                    </div>}

                    {!loading &&
                    <React.Fragment>

                        {postal && data?.dealerships &&
                        <div className="overflow-auto overflow-y-scroll my-4" style={{height: "40vh"}}>
                            {map(data.dealerships, (dealership) => {
                                const isSelected = dealership.id === inspection.dealer
                                return (
                                    <div key={dealership.id}
                                         onClick={() => handleSelectDealer(dealership)}
                                         className={`flex flex-row p-3 my-2 mr-2 border cursor-pointer ${isSelected ? "border-color_one text-color_one" : "border-color_five text-color_three"}`}>

                                        <div className="w-1/3">
                                            <span className="font-semibold">{dealership.distance} </span>
                                            {t("km")}
                                        </div>
                                        <div className="flex-1">
                                            <div className="text-color_one">{dealership.name}</div>
                                            <div className="text-12 flex flex-col">
                                                <div>{dealership.street}</div>
                                                <div>
                                                    {dealership.zip} {dealership.city}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>}


                        {errors && errors["dealer"] && (
                            <div className="flex items-center justify-center mb-2">
                                <div className="text-color_error text-12"> {errors["dealer"]}</div>
                            </div>
                        )}
                        <WizardButtons onPrev={handlePrevious} validatorFunction={validator}/>
                    </React.Fragment>}
                </React.Fragment>
            </div>
        );
    }
;

export default DealerLocator;
