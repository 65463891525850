import {useEffect, useState} from "react";
import {head} from "lodash";

export const useHeight = (params) => {
    const [totalHeight, setTotalHeight] = useState(0);

    const onScroll = (e) => {
        window.removeEventListener("scroll", onScroll)
        calcOffset()
    };

    useEffect(() => {
        window.addEventListener('scroll', onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const calcOffset = () => {
        let height = 0;
        params.map(param => {
            const selector = document.getElementsByClassName(param);
            if (selector.length > 0) {
                const elHeight = head(selector).offsetHeight;
                height = height + elHeight
            }
        });

        setTotalHeight(height)
    };

    return totalHeight
}
