import React from 'react';
import {useTranslation} from "react-i18next";

const YesNo = ({value, error, onChange}) => {
    const {t} = useTranslation();

    const handleYes = () => {
        onChange("yes");
    };

    const handleNo = () => {
        onChange("no");
    };



    return (
        <div className={`flex bg-white rounded-full border items-center font-12 text-bold text-color_three cursor-pointer ${(error) ? 'border-color_error text-color_error' : 'border-color_four '}`} style={{minWidth: 100}}>

            <div className={`flex  items-center justify-center h-8 w-1/2 border-r ${error ? 'border-color_error' : "border-color_four"} border-color_four rounded-l-full ${(value === "yes") ? 'bg-color_one text-white' : 'bg-white'}`} onClick={handleYes}>{t('Yes')}</div>
            <div className={`flex items-center justify-center h-8 w-1/2 rounded-r-full ${(value === "no") ? 'bg-color_one text-white' : 'bg-white'}`} onClick={handleNo}>{t('No')}</div>


        </div>
    );
};

export default YesNo;
