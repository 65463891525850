import React, {useContext, useEffect} from 'react';
import DataContext from "../../../data/context";
import {useTranslation} from "react-i18next";
import StatusGeneral from "../components/StatusGeneral";
import {formatDateString, formatPrice} from "../../../utils";
import analyticstracker from "analyticstracker";
import { TAGMANAGER_PAGE_ARGS } from "../../../tagManager";

const Archived = () => {
    const {takeOver} = useContext(DataContext);
    const {t, i18n} = useTranslation();

    useEffect(() => {
        analyticstracker().trackImpression("page-impression");
      }, []);

    const tagInfo = TAGMANAGER_PAGE_ARGS({
        pageName: "car-quotation/valuation-2",
        section: "valuation-2-proposal-archived",
        language: i18n.language,
    });

    return (
        <React.Fragment>
            <StatusGeneral title={(takeOver.accepted) ? ("Historiek van uw aanvraag") : ("Oeps, te laat...")}/>

            <div className="md:w-3/4"
             data-tracking-event="page-impression"
             data-tracking-info={tagInfo}>

                {takeOver.proposed && !takeOver.accepted &&
                <React.Fragment>
                    <div className="flex flex-col items-center justify-center mb-6 bg-color_five rounded-lg p-6">
                        <div className="font-bold text-color_one mb-2">{t("Suggested price on ")} {formatDateString(takeOver.proposedDate)}</div>
                        <div className="font-bold text-color_one text-24 pt-4 pb-4">
                            {formatPrice(takeOver.proposedAmount)} <span className="text-12">{t("incl VAT")}</span>
                        </div>
                    </div>

                    <div className="mb-6 mt-4 text-color_three leading-6"
                         dangerouslySetInnerHTML={{
                             __html: t("Our proposal is based on the current supply and demand on the market and is therefore {{styling}} valid for 7 days {{stylingEnd}}.", {
                                 styling: '<span class="font-bold text-color_one">',
                                 stylingEnd: '</span />',
                                 interpolation: {escapeValue: false}
                             })
                         }}>
                    </div>

                    <p className="text-color_one font-bold text-16">Wens je alsnog een prijs voor je wagen?</p>
                    <div className="text-color_three mt-4 mb-6">{t("Feel free to contact us or visit us in store.")}</div>
                </React.Fragment>}

                {takeOver.proposed && takeOver.accepted &&
                <div className="flex flex-col items-center justify-center mb-6 bg-color_five rounded-lg p-6">
                    <div className="text-bold mb-2">{t("Accepted price on ")} {formatDateString(takeOver.proposedDate)}</div>
                    <div className="text-bold text-color_one text-24 pt-4 pb-4">
                        {formatPrice(takeOver.acceptedAmount)} <span className="text-12">{t("incl VAT")}</span>
                    </div>
                </div>}


            </div>
        </React.Fragment>
    )
};

export default Archived;
