import React from 'react';

const TextAreaField = ({containerClass, label, placeholder, value, onChange, errorText, required, heightClass}) => {
    const hasError = errorText !== undefined;
    return (
        <div className={containerClass}>

            <label className={`block uppercase tracking-wide ${hasError ? "text-red-400" : "text-gray-700"} text-12 font-bold mb-2`}>
                {label} {required ? "(*)" : ""}
            </label>

            <textarea
                className={`appearance-none block w-full ${hasError ? "bg-red-100 border-red-400" : "bg-white border-gray-400"} text-gray-700 border rounded py-3 px-4 mb-1 ${heightClass} focus:outline-none focus:border-primary`}
                onChange={e => onChange(e.target.value)}
                value={value}
                placeholder={placeholder}
            />

            {hasError && <div className="text-red-400 text-12">{errorText}</div>}
        </div>
    );
};

TextAreaField.defaultProps = {
    required: false,
    heightClass: "h-48"
};

export default TextAreaField;
