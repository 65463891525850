import React from 'react';
import {keys} from "lodash";
import {useTranslation} from "react-i18next";

const WizardError = ({errors}) => {
    const {t} = useTranslation();

    if (!keys(errors).length > 0) {
        return null;
    }

    return (
        <div className="flex items-center justify-center mb-2">
            <div className="text-color_error text-12"> {t("Complete the missing fields to continue")}</div>
        </div>

    );
};

export default WizardError;
