import React, {useContext, useEffect, useState} from 'react';
import WizardButtons from "../WizardButtons";
import {includes, filter, keys, map, orderBy, head} from "lodash";
import DataContext from "../../../data/context";
import {useTranslation} from "react-i18next";
import WizardError from "../WizardError";
import analyticstracker from "analyticstracker";
import {TAGMANAGER_TOOL_ARGS, TAGMANAGER_COMMERCE_ARGS} from '../../../tagManager';

const Make = () => {
    const {inspection, data, setField, setNextVehicleDataStep, dealerConfig} = useContext(DataContext);
    const [errors, setErrors] = useState({});
    const {t} = useTranslation();

    useEffect(() => {
        if (errors && keys(errors).length > 0) {
            validate()
        }
    }, [inspection]);

    useEffect(() => {
        let aTrack = analyticstracker();
        aTrack.trackImpression('tool-start');
      }, []);

    const tagArgsStart = TAGMANAGER_TOOL_ARGS({
        event: 'start',
        toolStep: 'brand',
        toolStepNumber: "1",
    })

    const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})

    const validate = () => {
        let errors = {};

        if (!inspection.make) {
            errors['make'] = t("Please select a brand");
            let aTrack = analyticstracker();
            let errorTrackingInfo = TAGMANAGER_TOOL_ARGS({
                event: "error",
                toolStep: 'brand',
                toolStepNumber: "1",
                errorType: 'user-error',
                errorMessage: errors.make
            })
            let event = { "event": "tool-error", "info": JSON.parse(errorTrackingInfo), "commerce": JSON.parse(tagArgsCommerce) };
            aTrack.trackEvent(event);
        }

        setErrors(errors);

        return keys(errors).length <= 0;
    };

    const validator = () => {
        return validate();
    };

    useEffect(() => {
        if (inspection.make) {
            setNextVehicleDataStep();
        }
    }, [inspection]);

    const handleMakeClick = (e, make) => {
        setField('make', make.key);
        console.log(make.key)
        let aTrack = analyticstracker();
        aTrack.trackInteraction(e)

        setNextVehicleDataStep();
    };


    const allMakes = (data && data.makes) ? filter(data.makes, (m) => !m.disabled) : [];
    const dealerMakes = orderBy(filter(dealerConfig.makes, (m) => m.takeOver), ['priority', 'name'], ['asc','asc']);
    const otherMakes = orderBy(filter(allMakes, (m) => !includes(map(dealerConfig.makes, (m) => m.key), m.key)), ['name'], ['asc']);

    return (
        <div data-tracking-event="tool-start" data-tracking-info={tagArgsStart} data-tracking-commerce={tagArgsCommerce}>
            <div className="text-color_one text-24 font-bold mb-4 mt-10 text-center">{t('Make')}</div>

            <div className="text-16 leading-6 mb-4 mt-6">{t('Select the brand of your vehicle')}:</div>

            {dealerMakes && dealerMakes.length > 0 &&
            <React.Fragment>
                <div className="flex flex-wrap mb-3">
                    {map(dealerMakes, (make, i) => {
                        const selected = make.key === inspection.make;
                        const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                            event: 'submit',
                            toolStep: 'brand',
                            toolStepNumber: "1",
                            toolStepOption: make.name,
                          })
                        const tagArgsCommerce = TAGMANAGER_COMMERCE_ARGS({})
                        return (
                            <div key={`make_${make.key}`} className={`w-1/2 mb-2 ${((i % 2) !== 1) ? 'pr-2' : ''}`}>
                                <div className={`flex items-center flex-col justify-center bg-color_five border text-14 rounded-lg cursor-pointer ${(selected) ? 'border-color_one text-color_one' : 'border-color_five text-color_three'} hover:border-color_one hover:text-color_one capitalize p-2`}
                                     // style={{height: 70}}
                                     data-tracking-event="tool-submit"
                                     data-tracking-info={tagArgsSubmit}
                                     data-tracking-commerce={tagArgsCommerce}
                                     onClick={(e) => handleMakeClick(e, make)}>
                                    <i data-name={make.key.replace(" ","-")} className={`car-logo icon-${make.key.replace(" ","-")} ${selected ? "text-color_one" : ""}`} style={{fontSize: 50}}/>
                                    <span className="mt-2 font-bold w-full truncate text-center">{make.name}</span>
                                    {/*<span className={make.key}>{make.name}</span>*/}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <hr className="solid mb-5 border-color_five"/>
            </React.Fragment>}


            <div className="flex flex-wrap mb-10">
                {map(otherMakes, (make, i) => {
                    const selected = make.key === inspection.make;
                    const tagArgsSubmit = TAGMANAGER_TOOL_ARGS({
                        event: 'submit',
                        toolStep: 'brand',
                        toolStepNumber: "1",
                        toolStepOption: make.name,
                      })
                    return (
                        <div key={`make_${make.key}`} className={`w-1/3 mb-2 ${((i % 3) !== 2) ? 'pr-2' : ''}`}>
                            <div className={`flex items-center text-center justify-center bg-color_five border text-14 rounded-lg cursor-pointer ${(selected) ? 'border-color_one text-color_one' : 'border-color_five text-color_three'} hover:border-color_one hover:text-color_one capitalize`}
                                 style={{height: 70}}
                                 data-tracking-event="tool-submit"
                                 data-tracking-info={tagArgsSubmit}
                                 data-tracking-commerce={tagArgsCommerce}
                                 onClick={(e) => handleMakeClick(e,make)}>
                                {make.name}
                            </div>
                        </div>
                    )
                })}
            </div>

            <WizardError errors={errors}/>

            <WizardButtons
                validatorFunction={validator}/>
        </div>
    );
};

export default Make;
