import {Route} from "react-router-dom";
import React, {useContext} from "react";
import DataContext from "../data/context";
import LoadingIndicator from "../components/indicators/LoadingIndicator";

const DealerRoute = ({children, ...rest}) => {
    const {dealer, dealerError} = useContext(DataContext);

    if(!dealer && dealerError === "" ){
        return <LoadingIndicator />
    }

    return (
        <Route {...rest}
               render={({location}) => children}
        />
    );
};

export default DealerRoute;
