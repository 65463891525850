import React from 'react';
import analyticstracker from "analyticstracker";

const ConfirmationModal = ({title, text, acceptButtonText, declineButtonText, onAccept, onDecline, trackingEvent=undefined, trackingInfo=undefined, trackingCommerce=undefined}) => {

    return (
        <div className="flex items-center justify-center fixed left-0 right-0 top-0 bottom-0 z-50" style={{background: "rgba(0, 0, 0, 0.6)"}}>
            <div className="p-6 flex flex-col border-2 border-color_one rounded-lg bg-white" style={{width: 350, minHeight: 120}}>
                <div className="text-color_one text-16 font-bold mb-3">{title}</div>
                <div className="text-12  mb-2 ">{text}</div>

                <div className="flex justify-end mt-6">
                    <div className="flex  h-8 bg-white mr-6 items-end justify-end cursor-pointer underline mr-4" onClick={onDecline}>{declineButtonText}</div>
                    <div
                        className="flex bg-color_one items-center justify-center h-10 rounded-full text-white cursor-pointer p-4"
                        data-tracking-event={trackingEvent}
                        data-tracking-info={trackingInfo}
                        data-tracking-commerce={trackingCommerce}
                        onClick={(e) => {
                            let aTrack = analyticstracker();
                            aTrack.trackInteraction(e)
                            onAccept()}
                        }
                    >
                        {acceptButtonText}
                    </div>
                </div>

            </div>
        </div>
    );
};

ConfirmationModal.defaultProps = {
    text: 'Are you sure?',
    acceptButtonText: 'Yes',
    declineButtonText: 'No'
};

export default ConfirmationModal;
