import React, {useContext, useState} from 'react';
import ProposedPending from "./ProposedPending";
import ProposedDocumentUpload from "./ProposedDocumentUpload";
import ProposedSubmitted from "./ProposedSubmitted";
import DataContext from "../../../data/context";
import {ClientContext, useMutation} from "graphql-hooks";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import {getDealerFromUrl} from "../../../utils";
import analyticstracker from "analyticstracker";
import {
    TAGMANAGER_TOOL_ARGS,
    TAGMANAGER_COMMERCE_ARGS
  } from "../../../tagManager";
import {useTranslation} from 'react-i18next';

export const ConfirmTakeOverMutation =
    `mutation TakeOverConfirm($key: String! $documents: [String]!) { 
        takeOverConfirm(key: $key documents: $documents) { 
            ok
            error
        }
    }`;

const Proposed = () => {
    const {inspection, takeOver, takeOverFetch} = useContext(DataContext);
    const [proposedPending, setProposedPending] = useState(false);
    const [proposedStatus] = useState(0);
    const [ setError] = useState("");
    const [confirmTakeOverMutation, {loading}] = useMutation(ConfirmTakeOverMutation);
    const {executeRecaptcha} = useGoogleReCaptcha();
    const client = useContext(ClientContext);

    const { i18n } = useTranslation();

    const handleConfirm = () => {
        handleTakeOverConfirm()
    };


    const handleTakeOverConfirm = async (file) => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha("valuation");

        client.setHeader('Captcha', token);


        const documentUrls = takeOver.documents.map((doc) => { return doc.url });

        confirmTakeOverMutation({variables: {key: inspection.key, documents: documentUrls}})
            .then(res => {
                const {ok, error} = res.data.takeOverConfirm;

                if (ok) {

                    // refetch status
                    takeOverFetch(inspection.key, getDealerFromUrl(window.location.pathname));

                  //  takeOverSetConfirmedHandler(true);
                  //  setProposedStatus(proposedStatus + 1);
                }

                if(error){
                    setError(error)
                }

            })
            .catch(error => {
                console.log(error);
            });
    };

    if (takeOver.conventionUploaded || (proposedPending && proposedStatus === 1)) {
        return <ProposedSubmitted/>
    } else if ((proposedPending && proposedStatus === 0) || (proposedPending === false && takeOver.documents.length > 0)) {
        return <ProposedDocumentUpload onConfirm={handleConfirm} confirmLoading={loading}/>
    } else {
        const onAccept = () => {
            let trackingEvent = "tool-complete"

            let trackingCommerce = TAGMANAGER_COMMERCE_ARGS({
                carConfigCode: inspection.key
            })

            let trackingInfo = TAGMANAGER_TOOL_ARGS({
                event: "complete",
                toolStep: "thank-you",
                toolStepNumber: "8",
                inspectionPartThree: true,
              });
            let event = { "event": trackingEvent, "info": JSON.parse(trackingInfo), "commerce": JSON.parse(trackingCommerce) };

            analyticstracker().trackEvent(event);
            setProposedPending(true)
        }

        return <ProposedPending 
            inspection={inspection} onAccept={() => onAccept()}/>
    }
};

export default Proposed;
