import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import DataContext from "../data/context";

const ErrorSummaryModal = () => {
    const {validationErrorsModalData, setValidationErrorsModalVisible} = useContext(DataContext);
    const {t} = useTranslation();

    if (validationErrorsModalData === undefined || validationErrorsModalData === null) return null;

    return (
        <div className="flex items-center justify-center fixed left-0 right-0 top-0 bottom-0 z-50" style={{background: "rgba(0, 0, 0, 0.6)"}}>
            <div className="p-6 flex flex-col border-2 border-color_one rounded-lg bg-white" style={{minWidth: 350, minHeight: 120}}>

                <div className="text-color_one text-16 font-bold mb-3">{t("Seems like we're missing some information here!")}</div>
                <div className="text-12  mb-6 ">{t("They are necessary for us to be able to calculate a takeover price.")}</div>

                <div className="ml-2">
                    {Object.values(validationErrorsModalData).map((error) => {
                        return (
                            <div className="text-12 mb-2"><i className="fal fa-exclamation-triangle mr-2"></i>{error}</div>
                        )
                    })}
                </div>

                <div className="flex justify-end mt-6">
                    <div className="flex bg-color_one items-center justify-center h-10 rounded-full text-white cursor-pointer p-4" onClick={() => setValidationErrorsModalVisible(undefined)}>{t("Close")}</div>
                </div>

            </div>
        </div>
    );
};

export default ErrorSummaryModal;
