import React, {useContext, useState} from 'react';
import CustomSvg from "./CustomSvg";
import DataContext from "../../../data/context";
import {find, map} from "lodash";
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../tailwind.config.js';
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";

const fullConfig = resolveConfig(tailwindConfig);


const LocationOverview = ({damageLocations, selectedLocation, onLocationClick}) => {
    const {inspection} = useContext(DataContext);
    const [availableLocations, setAvailableLocations] = useState([]);
    const [popupStyle, setPopupStyle] = useState(undefined);
    const {t} = useTranslation();

    const handleLocationClick = (locationId, x, y, otherLocations, layerX, layerY) => {
        if (isMobile && otherLocations && otherLocations.length > 1) {
            // if (otherLocations && otherLocations.length > 1) {

            const locations = map(otherLocations, (id) => {
                return find(damageLocations, (loc) => loc.id == id);
            });
            setAvailableLocations(locations);

            const popupWidth = 200;
            setPopupStyle({
                top: layerY,
                left: (layerX > (window.innerWidth / 2) ? layerX - popupWidth : layerX),
                maxWidth: popupWidth,
                minWidth: popupWidth
            })
        } else {
            const damageLocation = find(damageLocations, (loc) => loc.id == locationId);
            onLocationClick((damageLocation) ? damageLocation.key : null)
        }
    };

    const selectedDamageLocation = find(damageLocations, (loc) => loc.key === selectedLocation);
    const mappedDamages = map(inspection.damages, (damage) => {
        const damageLocation = find(damageLocations, (loc) => loc.key === damage.location)
        return {
            ...damage,
            locationId: (damageLocation) ? damageLocation.id : undefined,
        }
    });

    return (
        <React.Fragment>

            {/*<p className="text-color_three text-14 mb-8 leading-5">*/}
            {/*    {t("Select the damage locations on the car below and add a picture. To clearly demonstrate the size of the damage, hold an object up next to it as reference. E.g. car keys or board documents.")}*/}
            {/*</p>*/}

            <div id="vehicleSvg" className="relative">
                <CustomSvg uniqueId="SelfCheckDamages"
                           path={require(`./svg/damages.svg`)}
                           items={mappedDamages}
                           currentItem={(selectedDamageLocation) ? {locationId: selectedDamageLocation.id} : null}
                           onClick={handleLocationClick}
                           onDrop={() => window.alert('dropped')}
                           onBullsEyeMove={() => null}
                           onBullsEyeClick={() => null}
                           onBullsEyeDrop={() => null}
                           clickAllowed={true}
                           dropAllowed={false}
                           translations={{}}
                           hoverColor={fullConfig.theme.colors.color_one}
                />

                {availableLocations && availableLocations.length > 1 &&
                <div className="absolute bg-white border-2 rounded-lg border-color_one" style={{...popupStyle}}>
                    <div className="flex bg-color_one h-12 p-2 items-center text-14 font-bold text-white">
                        {t('Did you mean one of these areas?')}
                    </div>
                    {map(availableLocations, (loc, i) => {
                        return (
                            <div key={`location-${loc.id}_${i}`}
                                 className="flex flex-1 p-4 border-b border-color_four"
                                 onClick={() => {
                                     onLocationClick(loc.key);
                                     setAvailableLocations([]);
                                 }}>

                                <i className="fal fa-chevron-right text-color_one mr-4"/>
                                <span className="truncate"> {loc.description}</span>
                            </div>
                        )
                    })}
                </div>}
            </div>


        </React.Fragment>
    )


};

export default LocationOverview;
